import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../context/UserProvider';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import axios from 'axios';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast'


const AddGeminiAPIkeyModal = (props) => {
    const [user, setUser] = useContext(UserContext)
    const [addingKey, setAddingKey] = useState(false)
    const [isKeyValid, setIsKeyValid] = useState(false)
    const [validatingKey, setValidatingKey] = useState(true)

    const [key, setKey] = useState(props?.apiKey ? props.apiKey : '')
    const [error, setError] = useState(null)
    const [viewingKey, setViewingKey] = useState(false)

    useEffect(() => {
        setKey(props?.apiKey ? props.apiKey : '')
    }, [props.show])


    const addKey = e => {
        e.preventDefault()
        setAddingKey(true)
        ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, selected_user_id: props?.selectedUser?.user_id ? props.selectedUser.user_id : null, editingField: 'gemini_api_key', newValue: key })
            .then(res => {
                if (props.selectedUser && props.setSelectedUser) {
                    props.setSelectedUser({ ...props.selectedUser, gemini_api_key: res.data.newValue })
                } else {
                    setUser({ ...user, gemini_api_key: res.data.newValue })
                }
                setAddingKey(false)
                toast('Gemini API Key is saved', {
                    icon: "🔑"
                })
                props.onHide()
                if ("handleSubmit" in props) {
                    props.handleSubmit(null, key)
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingKey(false)
            })
    }

    useEffect(() => {
        setTimeout(() => {
            checkApiKey()
        }, 500)
    }, [key.length])

    const checkApiKey = async () => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${key}`;
        if (key.length > 0) {
            try {
                setValidatingKey(true)
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 200) {
                    console.log('Gemini API Key is valid');
                    setIsKeyValid(true)
                    setValidatingKey(false)
                }
            } catch (error) {
                console.log('Invalid API Key');
                setIsKeyValid(false)
                setValidatingKey(false)
            }
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='px-4'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.heading ? props.heading : 'API key not found'}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={addKey}>
                <Modal.Body className='px-4 pt-5 pb-0'>
                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '25px', marginBottom: '1rem' }} />
                    <p className='font-s'>{props.title ? props.title : 'Add your Gemini API key'}</p>
                    <div className={`mx-0 px-0 pt-3`}>
                        <div className='profile-item-header px-0 py-0 mb-4'>
                            <div className='input-grp'>
                                <label>Gemini API Key</label>
                                <div className='input-with-icon'>
                                    <input required type={viewingKey ? 'text' : 'password'} id='key' value={key} onChange={e => { setKey(e.target.value) }} className='' />
                                    {viewingKey
                                        ?
                                        <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                        :
                                        <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='profile-item-footer'>
                            <p className='text-danger'>{error?.errorMsg}</p>
                        </div>
                        <p className='text-left'>Unsure where to find your Gemini API key? Check <a target="_blank" href="https://sagecollab.com/how-to-find-your-gemini-api-key">this guide <FiExternalLink /></a></p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='btn-row-container left my-3'>
                        {key.length
                            ?
                            <>
                                {validatingKey
                                    ?
                                    <div className='d-flex font-xs justify-content-center align-items-center'>
                                        Validating
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>

                                    :
                                    <>
                                        {key.length > 0
                                            &&
                                            <>
                                                {isKeyValid
                                                    ?
                                                    <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                        <RiCheckLine className='edit-icon accept-icon ' />
                                                        Valid Key
                                                    </div>

                                                    :
                                                    <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                        <RiCloseLine className='edit-icon reject-icon ' />
                                                        Invalid Key
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>

                            : ''
                        }
                    </div>
                    <button type="submit" className="button button-main" disabled={addingKey || !isKeyValid}>
                        {addingKey
                            ?
                            <>
                                {"handleSubmit" in props ? "Add & Continue" : "Add Key"}
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : "handleSubmit" in props ? "Add & Continue" : "Add Key"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddGeminiAPIkeyModal