import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

const AdModal = ({ title, content, buttons, show, onHide }) => {
    const [isModalHidable, setIsModalHidable] = useState(false)
    let defaultTime = 15
    const [timer, setTimer] = useState(defaultTime)


    let mounted = false
    useEffect(() => {
        if (show && timer === defaultTime) {
            setInterval(() => {
                setTimer(time => time - 1)
            }, 1000)
        } else {
            setTimer(15)
        }
    }, [show])

    return (
        <Modal
            show={show}
            onHide={timer <= 0 ? onHide : null}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='px-4'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title
                        ? title

                        : "Ad"
                    }
                </Modal.Title>

                <div className='d-flex align-items-center'>
                </div>
            </Modal.Header>
            <Modal.Body className='px-4'>
                {content
                    ? content

                    :
                    <>
                        <div className='ad-container'>
                            <a href={process.env.REACT_APP_AD_IMAGE_REDIRECTS_TO} target='_blank' title='Advertisement'>
                                <img src={process.env.REACT_APP_AD_IMAGE_SRC} alt="advertisement" />
                            </a>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div className='btn-row-container left my-3 w-100'>
                    {buttons
                        ? buttons

                        :
                        <>
                            <NavLink to="/upgrade" className="button px-3 w-100">Upgrade to get an ad free experience</NavLink>
                            <button disabled={timer > 0} className='button button-light w-100' onClick={timer <= 0 ? onHide : null}>
                                {timer > 0
                                    ?
                                    <span className='font-xs'>
                                        closes in {timer}s
                                    </span>

                                    : "close"
                                }
                            </button>
                        </>
                    }

                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AdModal