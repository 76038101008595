export const styleOptions = [
    "Academic",
    "Analytical",
    "Argumentative",
    "Conversational",
    "Creative",
    "Critical",
    "Descriptive",
    "Epigrammatic",
    "Epistolary",
    "Expository",
    "Informative",
    "Instructive",
    "Journalistic",
    "Metaphorical",
    "Narrative",
    "Persuasive",
    "Poetic",
    "Satirical",
    "Technical",
]
export const toneOptions = [
    "Authoritative",
    "Clinical",
    "Cold",
    "Confident",
    "Cynical",
    "Emotional",
    "Empathetic",
    "Formal",
    "Friendly",
    "Humorous",
    "Informal",
    "Ironic",
    "Optimistic",
    "Pessimistic",
    "Playful",
    "Sarcastic",
    "Serious",
    "Sympathetic",
    "Tentative",
    "Warm",
]