import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/logo.svg'

const Footer = () => {
  return (
    <footer id="footer">
      <div className='footer-links'>
        <a href="https://sagecollab.com/category/updates/" target="_blank">Updates</a>
        <a href="https://sagecollab.com/privacy-policy/" target="_blank">Privacy Policy</a>
        <a href="https://sagecollab.com/terms-of-service/" target="_blank">Terms of Service</a>
      </div>
      <div className="home-branding responsive px-3">
        <img
          alt="logo"
          src={logo}
          className="home-logo"
        />
        <span className="home-company" style={{ color: "#fff", fontWeight: "600" }}>SageCollab</span>
      </div>
    </footer>
  )
}

export default Footer