import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { NavLink, useNavigate } from "react-router-dom"
import Axios from '../api/axios'
import { MdDone } from 'react-icons/md'
import { CgLink } from 'react-icons/cg'
import { Helmet } from 'react-helmet'

const SendPasswordResetCode = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [resetLinkSent, setResetLinkSent] = useState(false)
    const navigate = useNavigate()

    const sendResetLink = e => {
        e.preventDefault()
        setLoading(true)
        Axios.post('/auth/sendResetEmail', { email })
            .then(res => {
                setLoading(false)
                if (res.data) {
                    setResetLinkSent(true)
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    return (
        <div className='container box'>
            <Helmet>
                <title>Reset your password - SageCollab</title>
                <meta name="description" content="Reset your password on SageCollab to regain access to your account. Ensure your account's security by updating your password regularly." />
                <meta name="keywords" content="reset password, SageCollab, account security, password update" />
                <meta property="og:title" content="Reset your password - SageCollab" />
                <meta property="og:description" content="Reset your password on SageCollab to regain access to your account. Ensure your account's security by updating your password regularly." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://app.sagecollab.com/send-password-reset-code" />
                <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Reset your password - SageCollab" />
                <meta name="twitter:description" content="Reset your password on SageCollab to regain access to your account. Ensure your account's security by updating your password regularly." />
                <meta name="twitter:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
            </Helmet>
            
            <div className='form md pt-0'>
                {!resetLinkSent
                    ?
                    <>
                        <h2>Forgot Password</h2>
                        <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>A password reset link will be sent to your registered email address</p>
                        <form className='form-container' onSubmit={sendResetLink}>
                            <div className='input-grp'>
                                <label htmlFor="username-email">Email <span className='text-danger'>*</span></label>
                                <input autoFocus required type="email" id="username-email" value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className='btn-container'>
                                {/* <p className='subtitle text-danger'>{error}</p> */}
                                <button type="submit" className="button button-main" disabled={loading}>
                                    {loading
                                        ?
                                        <>
                                            Continue
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </>

                                        : "Continue"
                                    }
                                </button>
                            </div>
                        </form>
                    </>

                    :
                    <>
                        <CgLink className='declined-icon text-success' />
                        <h2>Reset Link Sent</h2>
                        <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>We have sent you a password reset link on your registered email. Open that link to set your new password.</p>
                        <button type="submit" className="button button-main" onClick={() => navigate(-1)}>
                            Go Back
                        </button>
                    </>
                }
            </div>
        </div>
    )
}

export default SendPasswordResetCode