import React from 'react'
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { RiCloseLine } from 'react-icons/ri';

const ToastNotifications = () => {
    return (
        <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName="my-5 mx-2"
            containerStyle={{}}
            toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },

                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'yellow',
                        secondary: 'orange',
                    },
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                                <button className='edit-btn toast-close-btn' title='clear' onClick={() => toast.dismiss(t.id)}><RiCloseLine className='edit-icon reject-icon' /></button>
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    )
}

export default ToastNotifications