import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { BiBlock } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';

const UpgradeCancelled = () => {
  const [time, setTime] = useState(5);
  const navigate = useNavigate()

  setTimeout(() => {
    if (time === 0) {
      navigate('/upgrade')
    } else {
      setTime(time - 1)
    }
  }, 1000)

  return (
    <div className='container box'>
      <Helmet>
        <title>Failed - SageCollab</title>
      </Helmet>

      <div className='flexed-container column'>
        <BiBlock className='declined-icon' />
        <h2>Upgrade Failed</h2>
        <p className='text-center'>Could not upgrade your subscription, please retry. Redirecting you back to upgrade page in {time}s...</p>
        {/* <button className="button button-main" onClick={() => { }}>Go Back</button> */}
      </div>
    </div>
  )
}

export default UpgradeCancelled