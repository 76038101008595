import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import { toast } from 'react-hot-toast'
import { FiExternalLink } from "react-icons/fi"
import { formatDate } from '../utils/helper'
import { IoCheckmark, IoCopy } from 'react-icons/io5'
import { MdDelete, MdModeEditOutline } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { EventContext } from '../context/EventProvider'
import { NavLink } from 'react-router-dom'
import LoadingSkeleton from '../components/LoadingSkeleton'
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet'

const ManageExplorerPrompts = () => {
    const { eventData, emitEvent } = useContext(EventContext);
    const [user] = useContext(UserContext)
    const [prompts, setPrompts] = useState([])
    const [backupPrompts, setBackupPrompts] = useState([])
    const [loadingPrompts, setLoadingPrompts] = useState(true)
    const [addingPrompt, setAddingPrompt] = useState(false)
    const [updatingPrompt, setUpdatingPrompt] = useState(false)

    const [newPrompt, setNewPrompt] = useState({
        title: '',
        text: ''
    })
    const [selectedPrompt, setSelectedPrompt] = useState('')
    const [deletingPrompt, setDeletingPrompt] = useState(false)

    const [promptSearchText, setPromptSearchText] = useState("")

    const [readingMore, setReadingMore] = useState(false)

    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    const fetchAllExplorerPormpts = () => {
        setLoadingPrompts(true)
        ProtectedAxios.post('/users/getAllExplorerPrompts', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setPrompts(res.data)
                    setBackupPrompts(res.data)
                    setLoadingPrompts(false)
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setLoadingPrompts(false)
            })
    }

    useEffect(() => {
        fetchAllExplorerPormpts()
    }, [])

    const addExplorerPrompt = (e) => {
        e.preventDefault();
        setAddingPrompt(true)
        ProtectedAxios.post('/users/addExplorerPrompt', { user_id: user.user_id, ...newPrompt })
            .then(res => {
                if (res.data) {
                    setPrompts(prev => {
                        let updatedPrompts = [...prev]
                        updatedPrompts.unshift({ explorer_prompt_id: res.data.explorer_prompt_id, prompt_title: newPrompt.title, prompt_text: newPrompt.text, created_by: user.user_id })
                        return updatedPrompts
                    })
                    setAddingPrompt(false)
                    closeAddPromptModal()
                    toast.success("New prompt added")
                    setNewPrompt({ title: '', text: '' })
                }
            })
            .catch(err => {
                console.log(err);
                setAddingPrompt(false)
                toast.error(err.response.data.error)
            })
    }

    const deleteExplorerPrompt = (_explorer_prompt_id) => {
        setDeletingPrompt(true)
        ProtectedAxios.post('/users/deleteExplorerPrompt', { explorer_prompt_id: _explorer_prompt_id })
            .then(res => {
                if (res.data) {
                    const indexOfDeletedPrompt = prompts.findIndex(prompt => prompt.explorer_prompt_id === _explorer_prompt_id)
                    const updatedPrompts = [...prompts]
                    updatedPrompts.splice(indexOfDeletedPrompt, 1)

                    setPrompts(updatedPrompts)

                    setDeletingPrompt(false)
                    toast.success("Prompt deleted")
                }
            })
            .catch(err => {
                console.log(err);
                setDeletingPrompt(false)
                toast.error(err.response.data.error)
            })
    }

    const updateExplorerPrompt = (e) => {
        e.preventDefault()
        setUpdatingPrompt(true)
        ProtectedAxios.post('/users/updateExplorerPrompt', { user_id: user.user_id, explorer_prompt_id: selectedPrompt.explorer_prompt_id, updated_title: selectedPrompt.prompt_title, updated_text: selectedPrompt.prompt_text })
            .then(res => {
                if (res.data) {
                    let indexOfUpdatedPrompt = prompts.findIndex(prompt => prompt.explorer_prompt_id === selectedPrompt.explorer_prompt_id)
                    let updatedPrompts = [...prompts]
                    updatedPrompts[indexOfUpdatedPrompt] = { ...updatedPrompts[indexOfUpdatedPrompt], prompt_title: selectedPrompt.prompt_title, prompt_text: selectedPrompt.prompt_text }
                    setPrompts(updatedPrompts)

                    let indexOfUpdatedBackupPrompt = backupPrompts.findIndex(prompt => prompt.explorer_prompt_id === selectedPrompt.explorer_prompt_id)
                    let updatedBackupPrompts = [...backupPrompts]
                    updatedBackupPrompts[indexOfUpdatedBackupPrompt] = { ...updatedPrompts[indexOfUpdatedBackupPrompt], prompt_title: selectedPrompt.prompt_title, prompt_text: selectedPrompt.prompt_text }
                    setBackupPrompts(updatedBackupPrompts)

                    setUpdatingPrompt(false)
                    closeUpdatePromptModal()
                    toast.success("Prompt updated")
                }
            })
            .catch(err => {
                console.log(err);
                setUpdatingPrompt(false)
                toast.error(err.response.data.error)
            })
    }


    const [addPromptModal, setAddPromptModal] = useState(false)
    const closeAddPromptModal = () => setAddPromptModal(false)
    const openAddPromptModal = () => setAddPromptModal(true)

    const [updatePromptModal, setUpdatePromptModal] = useState(false)
    const closeUpdatePromptModal = () => setUpdatePromptModal(false)
    const openUpdatePromptModal = () => setUpdatePromptModal(true)


    const handleSearch = () => {
        if (promptSearchText.length === 0) {
            setPrompts(backupPrompts)
        }
        else {
            setPrompts(prev => {
                let updatedPrompts = backupPrompts
                return updatedPrompts.filter(prompts => prompts.prompt_title.toLowerCase().includes(promptSearchText.toLowerCase()) || prompts.prompt_text.toLowerCase().includes(promptSearchText.toLowerCase()))
            })
        }
    }

    useEffect(() => {
        handleSearch()
    }, [promptSearchText])


    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = e.target || e;
        const lineCount = textarea.value.split('\n').length;

        let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

        // Calculate the number of rows needed based on line count and content height
        const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

        if (e.target) {
            if (e.target.value.length === 0) {
                textarea.rows = 1;
            } else {
                textarea.rows = rowsNeeded;
            }
        } else {
            if (e.value.length === 0) {
                textarea.rows = 1;
            } else {
                textarea.rows = rowsNeeded;
            }
        }
    }


    const editPromptTextarea = useRef(null)
    //for prompt edit modal - prompt textarea - to automatically give textarea height according to the content
    useEffect(() => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin around the root
            threshold: 1.0, // 100% of the element must be in view
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // The textarea is fully in view
                    // You can check if its value is set here
                    if (selectedPrompt?.prompt_text) {
                        handleTextareaChange(editPromptTextarea.current)
                    }
                }
            });
        }, options);

        // Start observing the textarea element when the component mounts
        if (editPromptTextarea.current) {
            observer.observe(editPromptTextarea.current);
        }

        // Clean up the observer when the component unmounts
        return () => {
            if (editPromptTextarea.current) {
                observer.unobserve(editPromptTextarea.current);
            }
        };
    }, [selectedPrompt]);


    return (
        <section className='container py-5'>
            <Helmet>
                <title>Explorer Prompts - SageCollab</title>
            </Helmet>

            <div className="d-flex align-items-center justify-content-between gap-2">
                <h2 className="m-0">Manage Explorer Prompts</h2>
                <button title="Add New Prompt" className='button' onClick={openAddPromptModal}>Add</button>
            </div>
            <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
                <input
                    className='form-input'
                    type="text"
                    placeholder="search template"
                    value={promptSearchText}
                    onChange={e => setPromptSearchText(e.target.value)}
                />
            </div>
            <div className='saved-prompts-list container'>
                {loadingPrompts
                    ?
                    <div className='d-flex flex-column gap-3'>
                        <LoadingSkeleton type='saved-prompt' />
                        <LoadingSkeleton type='saved-prompt' />
                        <LoadingSkeleton type='saved-prompt' />
                    </div>

                    :
                    <>
                        {prompts.length === 0
                            ?
                            <div className='saved-prompt-list-item'>
                                No explorer prompts found.
                            </div>
                            :
                            <>
                                {prompts.map((prompt, i) => {
                                    return (
                                        <div className={`saved-prompt-list-item ${(selectedPrompt === prompt && deletingPrompt) ? "deleting-saved-prompt" : ""}`} key={i}>
                                            <div className='saved-prompt-left'>
                                                <p className='saved-prompt'>
                                                    <p style={{ fontSize: 'var(--size-l)', fontWeight: "500", marginBottom: '3px' }}>{prompt.prompt_title}</p>
                                                    {selectedPrompt === prompt && readingMore
                                                        ?
                                                        <>
                                                            {prompt.prompt_text}&nbsp;&nbsp;&nbsp;<a className='link cursor-pointer' onClick={() => { setReadingMore(false) }}>read less</a>
                                                        </>

                                                        :
                                                        <div onClick={() => {
                                                            if (prompt.prompt_text.length > 200) {
                                                                setSelectedPrompt(prompt);
                                                                setReadingMore(true)
                                                            }
                                                        }}
                                                        >
                                                            {prompt.prompt_text.substring(0, 200)}{prompt.prompt_text.length > 200 && <>.....<a className='link dark-link cursor-pointer' onClick={() => { setSelectedPrompt(prompt); setReadingMore(true) }}>read more</a></>}
                                                        </div>
                                                    }
                                                </p>
                                                {/* <p className='save-time subtitle'>{formatDate(new Date(prompt.created_at))}</p> */}
                                            </div>
                                            <div className={`saved-prompt-right`}>
                                                <div className='edit-btn-container justify-content-end align-start px-2'>
                                                    {/* <button type='button' className='edit-btn' title='copy' value={prompt.prompt_text} onClick={e => { setSelectedPrompt(prompt); navigator.clipboard.writeText(e.target.value).then(() => setCopied(true)) }}>
                                                        {copied && selectedPrompt === prompt
                                                            ?
                                                            <IoCheckmark className='edit-icon text-success' />
                                                            :
                                                            <IoCopy className='edit-icon' style={{ fill: "#3a3a3a" }} />
                                                        }
                                                    </button> */}
                                                    <button type='button' className='edit-btn cursor-pointer' title='delete' onClick={e => { setSelectedPrompt(prompt); openUpdatePromptModal() }} disabled={deletingPrompt}>
                                                        <MdModeEditOutline className='edit-icon' />
                                                    </button>
                                                    <button type='button' className='edit-btn cursor-pointer' title='delete' onClick={e => { setSelectedPrompt(prompt); deleteExplorerPrompt(prompt.explorer_prompt_id) }} disabled={deletingPrompt}>
                                                        <RiDeleteBin5Line className='edit-icon' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </>
                }
            </div>

            <Modal show={addPromptModal} onHide={closeAddPromptModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>New Prompt</Modal.Title>
                </Modal.Header>
                <form onSubmit={addExplorerPrompt}>
                    <Modal.Body>
                        <div className='form-container'>
                            <div className='input-grp'>
                                <label htmlFor="title">Title <span className='text-danger'>*</span> <span className={`${newPrompt.title.length > 20 ? 'text-warning' : ''}`}>({newPrompt.title.length}/20)</span></label>
                                <input required autoFocus type="text" id="title" value={newPrompt.title} onChange={e => setNewPrompt({ ...newPrompt, title: e.target.value })} />
                                <span className='mt-1' style={{ fontSize: 'var(--size-xxs)' }}>recommended length for title is of 20 characters</span>
                            </div>

                            <div className='input-grp'>
                                <label htmlFor="prompt">Prompt <span className='text-danger'>*</span></label>
                                <textarea
                                    rows={1}
                                    required
                                    type="text"
                                    id="prompt"
                                    value={newPrompt.text}
                                    onChange={e => { handleTextareaChange(e, null); setNewPrompt({ ...newPrompt, text: e.target.value }) }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='button-container'>
                            <button type='submit' disabled={addingPrompt} className='button'>
                                {addingPrompt
                                    ? <>
                                        Add Prompt
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>
                                    : "Add Prompt"}
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={updatePromptModal} onHide={closeUpdatePromptModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Update Prompt</Modal.Title>
                </Modal.Header>
                <form onSubmit={updateExplorerPrompt}>
                    <Modal.Body>
                        <div className='form-container'>
                            <div className='input-grp'>
                                <label htmlFor="title">Title <span className='text-danger'>*</span></label>
                                <input required autoFocus type="text" id="title" value={selectedPrompt?.prompt_title} onChange={e => setSelectedPrompt({ ...selectedPrompt, prompt_title: e.target.value })} />
                            </div>

                            <div className='input-grp'>
                                <label htmlFor="prompt">Prompt <span className='text-danger'>*</span></label>
                                <textarea
                                    ref={editPromptTextarea}
                                    rows={1}
                                    required
                                    type="text"
                                    id="prompt-edit"
                                    value={selectedPrompt?.prompt_text}
                                    onChange={e => { handleTextareaChange(e, null); setSelectedPrompt({ ...selectedPrompt, prompt_text: e.target.value }) }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='button-container'>
                            <button type='submit' disabled={updatingPrompt} className='button'>
                                {updatingPrompt
                                    ? <>
                                        Update Prompt
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>
                                    : "Update Prompt"}
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </section>
    )
}

export default ManageExplorerPrompts