import React, { useContext, useEffect } from 'react'
import BrokenLogo from '../components/custom-svgs/BrokenLogo'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserProvider'
import { Helmet } from 'react-helmet'

const PageNotFound = () => {
  const [user] = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <section className='d-flex flex-column justify-content-center align-items-center '>
      <Helmet>
        <title>Page Not Found - SageCollab</title>
      </Helmet>
      <BrokenLogo fill={'var(--color-secondary)'} style={{ width: 'calc(var(--size-xxl) * 4)' }} />
      <h2 className='text-center font-m'>The page you are looking for does not exist</h2>
      <button className='button mt-3' onClick={() => navigate(-1)}>Go Back</button>
    </section>
  )
}

export default PageNotFound