import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ProtectedAxios from '../api/protectedAxios';
import toast from 'react-hot-toast';
import { formatDate } from '../utils/helper';
import { RiSeoLine } from 'react-icons/ri';
import { MdClose, MdRefresh } from 'react-icons/md';
import { GoTriangleUp } from 'react-icons/go';

const PublicTemplateSearchEngineVisibilityDialog = ({ className, template_id, template_name, showAsLink, title }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [accordionState, setAccordionState] = useState(false)

    const [sendingForIndexing, setSendingForIndexing] = useState(false)
    const [sendingForRemoval, setSendingForRemoval] = useState(false)

    const [loadingIndexingData, setLoadingIndexingData] = useState(false)
    const [indexingData, setIndexingData] = useState(null)

    const sendTemplateUrlForIndexing = () => {
        setSendingForIndexing(true)
        ProtectedAxios.post("/users/sendTemplateUrlForIndexing", { template_id })
            .then(res => {
                setSendingForIndexing(false)
                toast.success("Your template has been submitted for indexing and will become visible on search engines. Please note that resubmitting the template will not expedite this process.")
            })
            .catch(err => {
                setSendingForIndexing(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }
    const sendTemplateUrlForRemoval = () => {
        setSendingForRemoval(true)
        ProtectedAxios.post("/users/sendTemplateUrlForRemoval", { template_id })
            .then(res => {
                setSendingForRemoval(false)
                toast.success("Your request to remove the template from search engines has been submitted.")
            })
            .catch(err => {
                setSendingForRemoval(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    const getIndexingStatus = () => {
        setLoadingIndexingData(true)
        setIndexingData(null)
        ProtectedAxios.get(`/users/getGoogleIndexingStatus?inspectionUrl=${window.origin}/marketplace/template/${template_id}`)
            .then(res => {
                setLoadingIndexingData(false)
                setIndexingData(res.data)
                setAccordionState(true)
            })
            .catch(err => {
                setLoadingIndexingData(false)
                console.log(err);
                setIndexingData(null)
                toast.error(err.response.data.error)
            })
    }

    const toggleAccordianState = () => {
        if (accordionState) {
            setAccordionState(false)
            setIndexingData(null)
        }
        else {
            getIndexingStatus()
        }
    }

    return (
        <>
            {!showAsLink
                ?
                <button className={`edit-btn position-relative ${className}`} id='share-button' title='share template' onClick={e => { handleShow() }}>
                    <RiSeoLine className='edit-icon dark-icon w-s' />
                </button>
                :
                <div className={`${className}`} onClick={handleShow}>
                    {title ? title : "Visibility"}
                </div>
            }



            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Visibility</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-3'>
                        <div>
                            <h1 className='font-s'>Search Engine Visibility</h1>
                            <p className='text-secondary'>{template_name}</p>
                            <p className=''>This public template can be made visible in search results by sending it for indexing. If it is already visible and you want it removed from search results, you can send a removal request.</p>
                            <p className='text-secondary'><span className='fw-medium'>Please note:</span> It can take anywhere from a few hours to several weeks for changes to be reflected in search results.</p>
                            <div className='d-flex w-100 gap-2 justify-content-end'>
                                <button
                                    className='button-plain-text bg-lightest text-danger'
                                    disabled={sendingForRemoval}
                                    onClick={sendTemplateUrlForRemoval}
                                >
                                    Request removal
                                    {sendingForRemoval
                                        &&
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    }
                                </button>
                                <button
                                    className='button-plain-text bg-lightest text-primary'
                                    disabled={sendingForIndexing}
                                    onClick={sendTemplateUrlForIndexing}
                                >
                                    Send for indexing
                                    {sendingForIndexing
                                        &&
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>


                        <div>
                            <button
                                type='button'
                                className='d-flex border-0 bg-transparent align-items-center justify-content-between w-100 mt-5'
                                onClick={toggleAccordianState}
                            >
                                <div className='d-flex w-100 align-items-center gap-3'>
                                    <p className='m-0'>Indexing Status</p>
                                    <span className='w-50 border-top' />
                                </div>
                                {loadingIndexingData
                                    ?
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>

                                    :
                                    <GoTriangleUp className={`w-s h-s transition-all ${accordionState ? "rotate-0" : "rotate-180"}`} />
                                }
                            </button>
                        </div>
                        <div className={`transition-all overflow-hidden ${accordionState ? "h-auto" : "h-0"}`}>
                            {indexingData
                                &&
                                <div className="container" id="indexing-data">
                                    <div className="row mt-2 pt-2">
                                        <div className="col-6 text-break">
                                            Verdict
                                        </div>
                                        <div className="col-6 text-break">
                                            {indexingData?.verdict ? indexingData.verdict : "-"}
                                        </div>
                                    </div>

                                    <div className="row mt-2 pt-2 border-top">
                                        <div className="col-6 text-break">
                                            Coverage State
                                        </div>
                                        <div className="col-6 text-break">
                                            {indexingData?.coverageState ? indexingData.coverageState : "-"}
                                        </div>
                                    </div>

                                    <div className="row mt-2 pt-2 border-top">
                                        <div className="col-6 text-break">
                                            Page Fetch Status
                                        </div>
                                        <div className="col-6 text-break">
                                            {indexingData?.pageFetchState ? indexingData.pageFetchState : "-"}
                                        </div>
                                    </div>

                                    <div className="row mt-2 pt-2 border-top">
                                        <div className="col-6 text-break">
                                            Last Crawled Time
                                        </div>
                                        <div className="col-6 text-break">
                                            {indexingData?.lastCrawlTime ? formatDate(new Date(indexingData.lastCrawlTime)) : "-"}
                                        </div>
                                    </div>

                                    <div className="row mt-2 pt-2 border-top">
                                        <div className="col-6 text-break">
                                            Crawled As
                                        </div>
                                        <div className="col-6 text-break">
                                            {indexingData?.crawledAs ? indexingData.crawledAs : "-"}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' onClick={() => handleClose()}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PublicTemplateSearchEngineVisibilityDialog