import React, { createContext, useState } from 'react';

// Create the event context
export const EventContext = createContext();

// Create the EventProvider component
export const EventProvider = ({ children }) => {
  const [eventData, setEventData] = useState(null);

  // Emit the event by updating the eventData state
  const emitEvent = (data) => {
    setEventData(data);
  };

  return (
    <EventContext.Provider value={{ eventData, emitEvent }}>
      {children}
    </EventContext.Provider>
  );
};
