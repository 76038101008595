import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'

const useSubscriptionDetails = () => {
    const [user, setUser] = useContext(UserContext)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    useEffect(() => {
        // console.log("user_id - ", user.user_id);
        // console.log("stripe_customer_id - ", user.stripe_customer_id);
        // console.log(user.stripe_customer_id && user.user_id !== user.stripe_customer_id);
        if (user.stripe_customer_id && user.user_id !== user.stripe_customer_id) {
            fetchSubscriptionDetails()
        }
    }, [user])

    const fetchSubscriptionDetails = () => {
        // if (user.user_id !== undefined) {
        // console.log("fetch subs details called");
        ProtectedAxios.post('/subscription/subscribedPlanDetails', { customer_id: user.stripe_customer_id, user_id: user.user_id })
            .then(res => {
                // console.log(res.data);
                setSubscriptionDetails(res.data)

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    // alert(err.response.data.error)
                }
            })
        // }
    }

    const refreshSubscriptionDetails = () => {
        fetchSubscriptionDetails()
    }

    return [subscriptionDetails, refreshSubscriptionDetails]
}

export default useSubscriptionDetails