import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { MdGppGood } from 'react-icons/md'
import { useLocation, useNavigate, useHistory, useParams } from 'react-router-dom';
import ProtectedAxios from '../../api/protectedAxios';
import { UserContext } from '../../context/UserProvider';
import { Helmet } from 'react-helmet';

const TopupSuccess = ({ updateTokens }) => {
    const [user, setUser] = useContext(UserContext)
    const [time, setTime] = useState(5);
    const navigate = useNavigate()
    let { session_id } = useParams()
    const [loading, setLoading] = useState(true)
    let mounted = false

    setTimeout(() => {
        if (time === 0) {
            navigate('/')
        } else {
            if (!loading) {
                setTime(time - 1)
            }
        }
    }, 1000)

    useEffect(() => {
        verifyPayment()
    }, [])

    const verifyPayment = () => {
        if (session_id) {
            if (!mounted) {     //work-around for this function being called twice for some reason
                setLoading(true)
                mounted = true
                setTimeout(() => {
                    ProtectedAxios.post('/users/verifyTopupSuccess', { session_id, user_id: user.user_id, customer_id: user.stripe_customer_id })
                        .then(res => {
                            if (res.data) {
                                if (res.data) {
                                    setLoading(false)
                                    updateTokens()
                                    toast('Topup completed, +3,000,000 tokens added', {
                                        icon: '🪙'
                                    })
                                }
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 501) {
                                navigate('/page-not-found')
                                setLoading(false)
                            }
                        })
                }, 1000)
            }
        }
        else {
            navigate('/page-not-found')
        }
    }
    return (
        <div className='container box'>
            <Helmet>
                <title>Success - SageCollab</title>
            </Helmet>
            <div className='overlay' />
            {loading
                ? <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                <div className='flexed-container column'>
                    <MdGppGood className='declined-icon text-success' />
                    <h2>Transaction Completed</h2>
                    <p className='text-center'>Your transaction have been successfully completed. Redirecting you back to Home page in {time}s...</p>
                    {/* <button className="button button-main" onClick={() => { }}>Go Back</button> */}
                </div>
            }
        </div>
    )
}

export default TopupSuccess