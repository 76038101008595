import { useState } from "react";
import LegacyTemplatesConverter from "../components/adminTools/LegacyTemplatesConverter";
import { Helmet } from "react-helmet";

const AdminTools = () => {
    const [selectedTool, setSelectedTool] = useState(<LegacyTemplatesConverter />)
    return (
        <section className="container py-5">
            <Helmet>
                <title>Admin Tools - SageCollab</title>
            </Helmet>
            <h4>Admin Tools</h4>
            <div className="d-flex mt-3" style={{ width: "fit-content" }}>
                <select className="form-select" value={selectedTool} onChange={e => setSelectedTool(e.target.value)}>
                    <option value={<LegacyTemplatesConverter />}>Legacy Template Converter</option>
                </select>
            </div>

            <div id="render-area" className="mt-5 pt-5 border-top container">
                {selectedTool}
            </div>
        </section>
    )
}

export default AdminTools
