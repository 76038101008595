import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation()

  useEffect(() => {
    //removes scroll bar
    document.body.style.height = "90vh";

    setTimeout(() => {
      //adds scrollbar back
      document.body.style.height = "auto";
      if (location.hash === '') {
        window.scrollTo(0, 0)
      }
    }, 1)

  }, [location])

  return null
}