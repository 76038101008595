import React from 'react'

const TokenIcon = ({ fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="34" viewBox="0 0 32 34" fill={fill ? fill : 'none'}>
            <path d="M26.5852 19.1725C27.8069 17.5341 28.5301 15.5021 28.5301 13.3012C28.5301 7.87151 24.1285 3.46988 18.6988 3.46988C15.817 3.46988 13.2247 4.70982 11.4265 6.6853C9.55233 6.94968 7.80363 7.60466 6.26543 8.56527C8.17399 3.55758 13.0209 0 18.6988 0C26.0449 0 32 5.95515 32 13.3012C32 18.0638 29.4969 22.2418 25.7346 24.5914C26.2953 23.1201 26.6024 21.5236 26.6024 19.8554C26.6024 19.6264 26.5966 19.3987 26.5852 19.1725Z" fill={fill ?fill :'#EBC90C'} />
            <path d="M15.9901 21.5116L15.5586 20.3253L16.4032 20.0177L15.4363 19.2058L16.0135 18.5175L14.7714 18.2984L14.9272 17.414L13.7404 17.8462L13.4337 17.003L12.6239 17.9686L11.9351 17.3903L11.7158 18.6346L10.8324 18.4788L11.2635 19.6641L10.4197 19.9714L11.3855 20.7824L10.8077 21.4715L12.0516 21.6909L11.8957 22.5755L13.081 22.1438L13.3881 22.988L14.1994 22.0204L14.8874 22.5981L15.1064 21.3557L15.9901 21.5116Z" fill={fill ?fill :'#EBC90C'} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6024 19.8554C26.6024 27.2015 20.6473 33.1566 13.3012 33.1566C5.95515 33.1566 0 27.2015 0 19.8554C0 12.5094 5.95515 6.55421 13.3012 6.55421C20.6473 6.55421 26.6024 12.5094 26.6024 19.8554ZM16.0486 21.5219L21.149 19.6644L20.7073 18.4502L16.4578 19.9978L19.9474 15.8365L18.9582 15.006L16.0521 18.4714L16.9946 13.1235L15.7228 12.8991L14.9371 17.3581L13.0799 12.2524L11.8665 12.6943L13.4122 16.9437L9.25666 13.4547L8.42659 14.4445L11.8913 17.3535L6.5441 16.4101L6.31987 17.6826L10.7719 18.4681L5.67313 20.325L6.11479 21.5392L10.3624 19.9923L6.87435 24.1517L7.86357 24.9823L10.7713 21.5148L9.82839 26.8658L11.1001 27.0902L11.8853 22.6345L13.7415 27.7377L14.955 27.2957L13.408 23.0428L17.5666 26.5344L18.3967 25.5446L14.9341 22.6373L20.2781 23.5802L20.5023 22.3077L16.0486 21.5219Z" fill={fill ?fill :'#EBC90C'} />
        </svg>
    )
}

export default TokenIcon