import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../context/UserProvider';
import Axios from '../api/axios';
import { toast } from 'react-hot-toast';
import PageNotFound from './PageNotFound';
import { Helmet } from 'react-helmet';

const EnterpriseChildJoin = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const secretCode = searchParams.get('secret_code');

    const [user, setUser] = useContext(UserContext)

    const [validating, setValidating] = useState(true)
    const [isInviteLinkValid, setIsInviteLinkValid] = useState(false)

    const [enterpriseAdminId, setEnterpriseAdminId] = useState(null)

    const [registeringUser, setRegisteringUser] = useState(false)
    const [registrationData, setRegistrationData] = useState({
        name: '',
        email: email,
        username: '',
        password: '',
        confirmPassword: ''
    })
    const [error, setError] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (!validating && isInviteLinkValid) {
            setUser({
                accessToken: '',
                refreshToken: '',
                name: '',
                email: '',
                role_id: ''
            })
        }
    }, [validating])

    useEffect(() => {
        validateInviteLink()
    }, [])

    //check if email and secretCode is valid
    const validateInviteLink = async () => {
        setValidating(true)
        Axios.post('/auth/enterprise/enterprise-child/validateInviteLink', { email, secretCode })
            .then(res => {
                setValidating(false)
                if (res.data.isValid) {      //means valid code and email
                    setEnterpriseAdminId(res.data.enterprise_admin_id)
                    setIsInviteLinkValid(true)
                    setValidating(false)
                } else {
                    setIsInviteLinkValid(false)
                    setValidating(false)
                }
            })
            .catch(err => {
                console.log(err);
                setIsInviteLinkValid(false)
                setValidating(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const register = e => {
        e.preventDefault()
        setRegisteringUser(true)
        setError('')

        if (registrationData.password !== registrationData.confirmPassword) {
            setError('Please add the same password in both fields')
            setRegisteringUser(false)
        }
        else if (registrationData.username.length < 3 || registrationData.username.length > 15) {
            setError('Username should be between 3 to 15 characters')
            setRegisteringUser(false)
        }
        else {
            registrationData.inviteEmail = email
            registrationData.secretCode = secretCode

            Axios.post('/auth/enterprise/enterprise-child/register', registrationData)
                .then(res => {
                    setRegisteringUser(false)
                    console.log('registration data - ', res.data);
                    if (res.data) {
                        console.log(res.data);
                        setUser(res.data)
                        navigate('/')
                        toast('You are successfully registered to SageCollab as Enterprise-Child user', {
                            icon: "👍"
                        })
                    } else {
                        toast.error('Could not register you at the moment, please try again later')
                    }
                })
                .catch(err => {
                    console.log(err);
                    setRegisteringUser(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    }
                    if (err.response.status === 400) {
                        setError(err.response.data.error)
                    }
                })
        }

    }

    return (
        <>
            <Helmet>
                <title>Register - SageCollab</title>
            </Helmet>

            {validating
                ?
                <div className='container py-5'>
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className="spinner-border spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>

                :
                <>
                    {!isInviteLinkValid
                        ? <PageNotFound />
                        :
                        <div className='container py-5'>
                            <h2 className='text-center'>Complete the setup for your enterprise-child account!</h2>

                            <form className='form-container my-5 container' style={{ maxWidth: '60rem' }} onSubmit={register}>
                                <div className='input-grp'>
                                    <label htmlFor="full-name-input">Full Name <span className='text-danger'>*</span></label>
                                    <input type="text" required id="full-name-input" value={registrationData.name} onChange={e => setRegistrationData({ ...registrationData, name: e.target.value })} />
                                </div>
                                <div className='form-grp'>
                                    <div className='input-grp'>
                                        <label htmlFor="email-input">Email <span className='text-danger'>*</span></label>
                                        <input disabled type="email" required id="email-input" value={registrationData.email} style={{ cursor: 'not-allowed' }} title='you cannot change the email. If you want to use another email, please contact your enterprise admin to send you invite to that email.' />
                                    </div>
                                    <div className='input-grp'>
                                        <label htmlFor="username-input">Username <span className='text-danger'>*</span></label>
                                        <input type="text" minLength={3} maxLength={15} required id="username-input" value={registrationData.username} onChange={e => setRegistrationData({ ...registrationData, username: e.target.value })} />
                                    </div>
                                </div>


                                <div className='form-grp'>
                                    <div className='input-grp'>
                                        <label htmlFor="password-input">Password <span className='text-danger'>*</span></label>
                                        <input type='password' id="password-input" required value={registrationData.password} onChange={e => setRegistrationData({ ...registrationData, password: e.target.value })} />
                                    </div>
                                    <div className='input-grp'>
                                        <label htmlFor="confirm-password-input">Confirm Password <span className='text-danger'>*</span></label>
                                        <input type='password' id="confirm-password-input" required value={registrationData.confirmPassword} onChange={e => setRegistrationData({ ...registrationData, confirmPassword: e.target.value })} />
                                    </div>
                                </div>

                                <div className='btn-container'>
                                    {error.length > 0
                                        &&
                                        <p className='subtitle text-danger'>{error}</p>
                                    }
                                    <button type="submit" className="button button-main" disabled={registeringUser}>
                                        {registeringUser
                                            ?
                                            <>
                                                Continue
                                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </>

                                            : "Continue"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default EnterpriseChildJoin