import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Axios from '../api/axios'
import PageNotFound from "./PageNotFound"
import { toast } from 'react-hot-toast'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiReset } from 'react-icons/bi'
import { MdFormatColorReset, MdOutlineLockReset } from 'react-icons/md'
import { Helmet } from 'react-helmet'

const ResetPassword = () => {
    const { resetCode } = useParams()
    const [loading, setLoading] = useState(true)
    const [codeStatus, setCodeStatus] = useState(0)
    const [userId, setUserId] = useState('')
    let isMounted = false

    const [resettingPassword, setResettingPassword] = useState(false)
    const [password, setPassword] = useState("")
    const [ViewingPassword, setViewingPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [viewingConfirmPassword, setViewingConfirmPassword] = useState("")

    const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
    const navigate = useNavigate()

    const [error, setError] = useState()

    useEffect(() => {
        if (!isMounted) {
            isMounted = true
            verifyResetCode()
        }
    }, [])

    const verifyResetCode = e => {
        setLoading(true)
        Axios.post('/auth/verifyPasswordResetCode', { resetCode })
            .then(res => {
                if (!res.data) {
                    setCodeStatus(-1)
                    setLoading(false)
                }
                else {
                    setUserId(res.data.user_id)
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 410) {
                    setCodeStatus(-1)
                    setLoading(false)
                }
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }
    const resetPassword = e => {
        e.preventDefault()
        setError("")
        if (password === confirmPassword) {
            setResettingPassword(true)
            Axios.post('/auth/resetPassword', { user_id: userId, password, resetCode })
                .then(res => {
                    if (res.data) {
                        setIsPasswordResetSuccess(true)
                        setResettingPassword(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                        setResettingPassword(false)
                    }
                })
        }
        else {
            setError("Please provide the same value for password and confirm password")
        }
    }

    return (
        <>
            <Helmet>
                <title>Reset Password - SageCollab</title>
            </Helmet>

            {loading
                ?
                <div className='container py-5'>
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className="spinner-border spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div>

                :
                <>
                    {codeStatus === -1
                        ? <PageNotFound />
                        :
                        <>

                            <div className='container box'>
                                <div className='form md pt-0'>
                                    {!isPasswordResetSuccess
                                        ?
                                        <>
                                            <h2>Reset Password</h2>
                                            <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>Please set new password for your account.</p>
                                            <form className='form-container' onSubmit={resetPassword}>
                                                <div className='input-grp'>
                                                    <label htmlFor="password">Password <span className='text-danger'>*</span></label>
                                                    <div className='input-with-icon'>
                                                        <input required type={ViewingPassword ? 'text' : 'password'} id='password' value={password} onChange={e => setPassword(e.target.value)} className='' />
                                                        {ViewingPassword
                                                            ?
                                                            <button type="button" className='button-icon edit-btn' title='hide password' onClick={() => { setViewingPassword(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                            :
                                                            <button type="button" className='button-icon edit-btn' title='view password' onClick={() => { setViewingPassword(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='input-grp'>
                                                    <label htmlFor="confirm-password">Confirm Password <span className='text-danger'>*</span></label>
                                                    <div className='input-with-icon'>
                                                        <input required type={viewingConfirmPassword ? 'text' : 'password'} id='confirm-password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='' />
                                                        {viewingConfirmPassword
                                                            ?
                                                            <button type="button" className='button-icon edit-btn' title='hide password' onClick={() => { setViewingConfirmPassword(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                            :
                                                            <button type="button" className='button-icon edit-btn' title='view password' onClick={() => { setViewingConfirmPassword(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='btn-container'>
                                                    <p className='subtitle text-danger'>{error}</p>
                                                    <button type="submit" className="button button-main" disabled={resettingPassword}>
                                                        {resettingPassword
                                                            ?
                                                            <>
                                                                Reset
                                                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                    <span className="sr-only"></span>
                                                                </div>
                                                            </>

                                                            : "Reset"
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </>

                                        :
                                        <>
                                            <BiReset className='declined-icon text-success' />
                                            <h2 className='text-center'>Password Successfully Reset</h2>
                                            <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>Password for your account have been successfully reset. You can now login to your account with your new password.</p>
                                            <button type="submit" className="button button-main" onClick={() => navigate("/login")}>
                                                Login
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default ResetPassword