import React, { useContext, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'react-hot-toast'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { Elements } from '@stripe/react-stripe-js'
import SetupIntentForm from './SetupIntentForm'
import Modal from 'react-bootstrap/Modal';
import { MdNotifications } from 'react-icons/md'

const SetPaymentMethod = ({ socket }) => {
    const [user, setUser] = useContext(UserContext)

    const [enterpriseSubscriptionAdded, setEnterpriseSubscriptionAdded] = useState(false)

    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
    const handleClosePaymentMethodModal = () => {
        if (enterpriseSubscriptionAdded) {
            //if closing modal after subscription is added so update the plansModalShown to true so that the floating button disappears
            setUser({ ...user, plans_model_shown: true })
            setShowPaymentMethodModal(false)
        } else {
            setShowPaymentMethodModal(false)
        }
    }
    const handleShowPaymentMethodModal = () => setShowPaymentMethodModal(true)

    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")


    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    }, [])

    useEffect(() => {
        savePaymentMethod()
    }, [])

    const savePaymentMethod = async () => {
        ProtectedAxios.post('/subscription/create-setup-intent', { customer_id: user.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    setClientSecret(res.data.clientSecret)
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    useEffect(() => {
        if (!user?.plans_model_shown) {
            if (user.role_id === 4) {
                handleShowPaymentMethodModal()
            }
        }
    }, [user])

    return (
        <>
            {user.plans_model_shown === false
                &&
                <button className='floating-subscription-button' onClick={handleShowPaymentMethodModal}>
                    <div className='icon-container'>
                        <MdNotifications />
                    </div>
                </button>
            }
            <Modal show={showPaymentMethodModal} onHide={handleClosePaymentMethodModal} className='stripe-payment-intent-modal' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Enterprise Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body className='container py-4 px-4'>
                    <>
                        <h6 className=''>Save a payment method to add Enterprise subscription in your account</h6>
                        <p>Subscribe to Enterprise plan at USD $20/month + additional USD $5/month for each child accounts.</p>
                        
                        With Enterprise subscription you get access to features like:
                        <ul>
                            <li>Add child accounts for your organization</li>
                            <li>View chats of your organization</li>
                            <li>Create templates for your organization</li>
                        </ul>

                        <div className='enterprise-stripe-container'>
                            {stripePromise && clientSecret
                                ?
                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                    <SetupIntentForm enterpriseSubscriptionAdded={enterpriseSubscriptionAdded} setEnterpriseSubscriptionAdded={setEnterpriseSubscriptionAdded} socket={socket} />
                                </Elements>
                                :
                                <div style={{ height: '15rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="spinner-border spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SetPaymentMethod