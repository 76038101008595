import Modal from 'react-bootstrap/Modal';

const CustomModal = ({ title, content, buttons, show, onHide, showFooter = true, size = "md", className }) => {
    return (
        <Modal
            className={className}
            show={show}
            onHide={onHide}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='px-4'>
                <Modal.Title id="contained-modal-title-vcenter" className='font-m'>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4'>
                {content}
            </Modal.Body>
            {showFooter
                &&
                <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div className='btn-row-container left my-3'>
                        {buttons}
                    </div>
                </Modal.Footer>
            }
        </Modal>
    )
}

export default CustomModal