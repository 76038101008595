import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserProvider'
import { toast } from 'react-hot-toast'
import ProtectedAxios from '../../api/protectedAxios'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { formatDate } from '../../utils/helper'
import { AiOutlineEdit } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet'
import { GoTriangleUp } from 'react-icons/go'

const ManageModels = () => {
    const [user] = useContext(UserContext)
    const [loadingModels, setLoadingModels] = useState(true)
    const [models, setModels] = useState([])

    const [selectedModel, setSelectedModel] = useState(null)
    const [updatingModel, setUpdatingModel] = useState(false)
    const [deletingModel, setDeletingModel] = useState(false)

    const [updateModal, setUpdateModal] = useState(false);
    const closeUpdateModal = () => setUpdateModal(false);
    const openUpdateModal = () => setUpdateModal(true);

    const [newModelRoleRestrictionAccordionState, setNewModelRoleRestrictionAccordionState] = useState(false)
    const [selectedModelRoleRestrictionAccordionState, setSelectedModelRoleRestrictionAccordionState] = useState(false)

    let roles = ["Free", "Standard", "Enterprise", "Enterprise Child"]

    const [addingModel, setAddingModel] = useState(false)
    const [newModelData, setNewModelData] = useState({
        model_name: '',
        is_active: 1,
        model_provider: 'OPENAI',
        max_token_limit: 0,
        role_restriction: {
            'Free': null,
            'Standard': null,
            'Enterprise Child': null,
            'Enterprise': null
        }
    })
    const [addModal, setAddModal] = useState(false);
    const closeAddModal = () => setAddModal(false);
    const openAddModal = () => setAddModal(true);

    useEffect(() => {
        fetchChatModels()
    }, [])

    const fetchChatModels = async () => {
        setLoadingModels(true)
        ProtectedAxios.post('/admin/fetchChatModels', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setModels(res.data)
                    setLoadingModels(false)
                }
            })
            .catch(err => {
                setLoadingModels(false)
                console.log(err);
                toast.error("could not fetch Models at the moment, please try again later.")
            })
    }
    const deleteChatModel = async (model_id, model_index) => {
        setDeletingModel(true)
        ProtectedAxios.post('/admin/deleteChatModel', { user_id: user.user_id, model_id, model_index })
            .then(res => {
                if (res.data) {
                    setDeletingModel(false)
                    fetchChatModels()
                }
            })
            .catch(err => {
                setDeletingModel(false)
                console.log(err);
                toast.error("could not delete Model at the moment, please try again later.")
            })
    }

    const updateChatModel = async () => {
        setUpdatingModel(true)
        ProtectedAxios.post('/admin/updateChatModel', { user_id: user.user_id, model_id: selectedModel?.model_id, model_name: selectedModel.model_name, model_index: selectedModel.model_index, is_active: selectedModel.is_active === "true" || selectedModel.is_active === true ? true : false, model_provider: selectedModel?.model_provider, max_token_limit: selectedModel?.max_token_limit, role_restriction: selectedModel?.role_restriction })
            .then(res => {
                if (res.data) {
                    closeUpdateModal()
                    setUpdatingModel(false)
                    fetchChatModels()
                }
            })
            .catch(err => {
                setUpdatingModel(false)
                console.log(err);
                toast.error("could not update Model at the moment, please try again later.")
            })
    }
    const addChatModel = async (e) => {
        e.preventDefault()
        setAddingModel(true)
        ProtectedAxios.post('/admin/addNewChatModel', { user_id: user.user_id, model_name: newModelData.model_name, is_active: newModelData.is_active === 1 ? true : false, model_provider: newModelData.model_provider, max_token_limit: newModelData.max_token_limit, role_restriction: newModelData.role_restriction })
            .then(res => {
                if (res.data) {
                    closeAddModal()
                    setNewModelData({
                        model_name: '',
                        is_active: 1,
                        model_provider: 'OPENAI',
                        max_token_limit: 0,
                        role_restriction: {
                            'Free': null,
                            'Standard': null,
                            'Enterprise Child': null,
                            'Enterprise': null
                        }
                    })
                    setAddingModel(false)
                    fetchChatModels()
                }
            })
            .catch(err => {
                setAddingModel(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error, { id: 'add-model-error' })
                }
                else if (err.response.status === 422) {
                    toast(err.response.data.error, { icon: '⚠️', id: 'add-model-error' })
                }
                else {
                    toast.error("could not add Model at the moment, please try again later.", { id: 'add-model-error' })
                }
            })
    }

    const handleNewPlanBasedRoleRestrictionCheckboxChange = (role) => {
        setNewModelData(prevData => ({
            ...prevData,
            role_restriction: {
                ...prevData.role_restriction,
                [role]: prevData.role_restriction[role] === null ? 0 : null
            }
        }));
    };

    const handleNewPlanBasedRoleRestrictionInputChange = (role, value) => {
        setNewModelData(prevData => ({
            ...prevData,
            role_restriction: {
                ...prevData.role_restriction,
                [role]: parseInt(value, 10)
            }
        }));
    };

    const handleSelectedPlanBasedRoleRestrictionCheckboxChange = (role) => {
        setSelectedModel(prevData => ({
            ...prevData,
            role_restriction: {
                ...prevData.role_restriction,
                [role]: prevData.role_restriction[role] === null ? 0 : null
            }
        }));
    };

    const handleSelectedPlanBasedRoleRestrictionInputChange = (role, value) => {
        setSelectedModel(prevData => ({
            ...prevData,
            role_restriction: {
                ...prevData.role_restriction,
                [role]: parseInt(value, 10)
            }
        }));
    };

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Manage Models - SageCollab</title>
            </Helmet>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '2vh' }}>
                <h2 className='text-left w-100'>Manage Models</h2>
                <div className='mt-3'>
                    <h3 className='font-xs'>Get token limits and other model details for:</h3>
                    <p className='mb-1 text-secondary'>OpenAI: <a className="text-secondary" style={{ color: "var(--color-light) !important" }} target="_blank" href="https://platform.openai.com/docs/models/continuous-model-upgrades">https://platform.openai.com/docs/models/continuous-model-upgrades</a></p>
                    <p className='text-secondary'>Gemini: <a className="text-secondary" style={{ color: "var(--color-light) !important" }} target="_blank" href="https://generativelanguage.googleapis.com/v1beta/models/<MODEL-NAME>?key=<YOUR-GEMINI-API-KEY>">{`https://generativelanguage.googleapis.com/v1beta/models/<MODEL-NAME>?key=<YOUR-GEMINI-API-KEY>`}</a></p>
                </div>
                <div className='w-100 d-flex justify-content-end my-4'>
                    <button className='button button-ghost' onClick={() => { openAddModal() }}>+ New Model</button>
                </div>
                <div className='table-container'>
                    <table className='api-keys-table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Model</th>
                                <th>Provider</th>
                                <th>Max Tokens</th>
                                <th>Status</th>
                                <th>Modified</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadingModels
                                ?
                                <tr>
                                    <td colSpan={7} className='text-center p-5'>
                                        <div className="spinner-border spinner-border-md" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </td>
                                </tr>

                                :
                                models.length === 0
                                    ?
                                    <tr>
                                        <td colSpan={7} className='text-center p-5'>
                                            You don't have any models
                                        </td>
                                    </tr>

                                    :
                                    <>
                                        {models.map((model, i) => {
                                            return (
                                                <tr key={i} className={`${(selectedModel?.model_id === model.model_id && deletingModel) ? 'deleting-key' : ''}`}>
                                                    <td style={{ width: "3rem" }}>{model.model_index}</td>
                                                    <td>{model.model_name}</td>
                                                    <td>{model.model_provider}</td>
                                                    <td>{model.max_token_limit}</td>
                                                    <td>
                                                        {model.is_active === true
                                                            ? 'Active'
                                                            : 'Disabled'
                                                        }
                                                    </td>
                                                    <td>{formatDate(new Date(model.modified_at))}</td>
                                                    <td>
                                                        <div className='d-flex flex-wrap'>
                                                            <button className='edit-btn' onClick={(e) => { setSelectedModel(model); deleteChatModel(model.model_id, model.model_index) }}><RiDeleteBin5Line className='edit-icon' /></button>
                                                            <button className='edit-btn' onClick={(e) => { setSelectedModel(model); openUpdateModal() }}><AiOutlineEdit className='edit-icon' /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Update Chat-Model MODAL */}
            <Modal show={updateModal} onHide={closeUpdateModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='upgrade-confirmation-body px-3 py-3'>
                        <div className='input-grp'>
                            <label htmlFor="model-name">Model</label>
                            <input type="text" id="model-name" value={selectedModel?.model_name} onChange={e => setSelectedModel({ ...selectedModel, model_name: e.target.value })} />
                        </div>

                        <div className='form-grp mt-4'>
                            <div className='input-grp'>
                                <label htmlFor="status">Status</label>
                                <select id="status" className='form-select' value={selectedModel?.is_active} onChange={e => setSelectedModel({ ...selectedModel, is_active: e.target.value })}>
                                    <option value={true}>Active</option>
                                    <option value={false}>Disabled</option>
                                </select>
                            </div>
                            <div className='input-grp'>
                                <label htmlFor="position">Position</label>
                                <select id="position" className='form-select' value={selectedModel?.model_index} onChange={e => setSelectedModel({ ...selectedModel, model_index: e.target.value })}>
                                    {models.map((model, key) => {
                                        return (
                                            <option key={key} value={key + 1}>{key + 1}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='form-grp mt-4'>
                            <div className='input-grp'>
                                <label htmlFor="provider-select">Provider</label>
                                <select id="provider-select" className='form-select' value={selectedModel?.model_provider} onChange={e => setSelectedModel({ ...selectedModel, model_provider: e.target.value })}>
                                    <option value="OPENAI">OPENAI</option>
                                    <option value="GEMINI">GEMINI</option>
                                </select>
                            </div>
                            <div className='input-grp'>
                                <label htmlFor="token_limit_input">Max Tokens</label>
                                <input type="number" id="token_limit_input" value={selectedModel?.max_token_limit} onChange={e => setSelectedModel({ ...selectedModel, max_token_limit: parseInt(e.target.value) })} />
                            </div>
                        </div>

                        {selectedModel?.role_restriction
                            &&
                            <div className='mt-5'>
                                <div>
                                    <button
                                        type='button'
                                        className='d-flex border-0 bg-transparent align-items-center justify-content-between w-100'
                                        onClick={() => setSelectedModelRoleRestrictionAccordionState(prev => !prev)}
                                    >
                                        <div className='d-flex w-100 align-items-center gap-3'>
                                            <p className='m-0'>Role Based Restrictions</p>
                                            <span className='w-50 border-top' />
                                        </div>
                                        <GoTriangleUp className={`w-s h-s transition-all ${selectedModelRoleRestrictionAccordionState ? "rotate-0" : "rotate-180"}`} />
                                    </button>
                                </div>
                                <div className={`transition-all overflow-hidden pt-2 ${selectedModelRoleRestrictionAccordionState ? "h-auto" : "h-0"}`}>
                                    <div>
                                        {Object.keys(selectedModel?.role_restriction)?.map(role => (
                                            <div key={role} className='row align-items-center my-2 mx-0'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedModel?.role_restriction[role] !== null}
                                                    onChange={() => handleSelectedPlanBasedRoleRestrictionCheckboxChange(role)}
                                                    className='col-2 w-xxs h-xxs'
                                                    id={`checkbox-role-restriction-${role}`}
                                                />
                                                <label className='col-4 font-xxs' htmlFor={`checkbox-role-restriction-${role}`}>{role}</label>
                                                <input
                                                    type="number"
                                                    value={selectedModel?.role_restriction[role] || 0}
                                                    disabled={selectedModel?.role_restriction[role] === null}
                                                    onChange={e => handleSelectedPlanBasedRoleRestrictionInputChange(role, e.target.value)}
                                                    className='col-4 w-auto disabled-input'
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' disabled={updatingModel} onClick={() => updateChatModel()}>
                        {updatingModel
                            ?
                            <>
                                Update Model
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : "Update Model"
                        }
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Add Chat-Model MODAL */}
            <Modal show={addModal} onHide={closeAddModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Model</Modal.Title>
                </Modal.Header>
                <form onSubmit={addChatModel}>
                    <Modal.Body>
                        <div className='upgrade-confirmation-body px-3 py-3'>
                            <div className='input-grp'>
                                <label htmlFor="model-name">Model</label>
                                <input autoFocus type="text" required id="model-name" value={newModelData.model_name} onChange={e => setNewModelData({ ...newModelData, model_name: e.target.value })} />
                            </div>

                            <div className='form-grp mt-4'>
                                <div className='input-grp'>
                                    <label htmlFor="status">Provider</label>
                                    <select id="status" className='form-select' value={newModelData.model_provider} onChange={e => setNewModelData({ ...newModelData, model_provider: e.target.value })}>
                                        <option value='OPENAI'>OpenAI</option>
                                        <option value='GEMINI'>Gemini</option>
                                        <option value='DEFAULT'>Default</option>
                                    </select>
                                </div>
                                <div className='input-grp'>
                                    <label htmlFor="status">Status</label>
                                    <select id="status" className='form-select' value={newModelData.is_active} onChange={e => setNewModelData({ ...newModelData, is_active: e.target.value })}>
                                        <option value={1}>Active</option>
                                        <option value={0}>Disabled</option>
                                    </select>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <div>
                                    <button
                                        type='button'
                                        className='d-flex border-0 bg-transparent align-items-center justify-content-between w-100'
                                        onClick={() => setNewModelRoleRestrictionAccordionState(prev => !prev)}
                                    >
                                        <div className='d-flex w-100 align-items-center gap-3'>
                                            <p className='m-0'>Role Based Restrictions</p>
                                            <span className='w-50 border-top' />
                                        </div>
                                        <GoTriangleUp className={`w-s h-s transition-all ${newModelRoleRestrictionAccordionState ? "rotate-0" : "rotate-180"}`} />
                                    </button>
                                </div>
                                <div className={`transition-all overflow-hidden pt-2 ${newModelRoleRestrictionAccordionState ? "h-auto" : "h-0"}`}>
                                    <div>
                                        {Object.keys(newModelData.role_restriction).map((role, i) => (
                                            <div key={role} className='row align-items-center my-2 mx-0'>
                                                <input
                                                    type="checkbox"
                                                    checked={newModelData.role_restriction[role] !== null}
                                                    onChange={() => handleNewPlanBasedRoleRestrictionCheckboxChange(role)}
                                                    className='col-2 w-xxs h-xxs'
                                                    id={`checkbox-role-restriction-${role}`}
                                                />
                                                <label className='col-4 font-xxs' htmlFor={`checkbox-role-restriction-${role}`}>{role}</label>
                                                <input
                                                    type="number"
                                                    value={newModelData.role_restriction[role] || 0}
                                                    disabled={newModelData.role_restriction[role] === null}
                                                    onChange={e => handleNewPlanBasedRoleRestrictionInputChange(role, e.target.value)}
                                                    className='col-4 w-auto disabled-input'
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='button' disabled={addingModel}>
                            {addingModel
                                ?
                                <>
                                    Add Model
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                : "Add Model"
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div >
    )
}

export default ManageModels