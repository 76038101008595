import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserProvider';
import { SocketProvider } from './context/SocketProvider';
import { EventProvider } from './context/EventProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  process.env.REACT_APP_ENVIRONMENT === "developmen"
    ?
    < React.StrictMode >
      <UserProvider>
        <EventProvider>
          <SocketProvider>
            <App />
          </SocketProvider>
        </EventProvider>
      </UserProvider>
    </React.StrictMode >
    
    :
    <UserProvider>
      <EventProvider>
        <SocketProvider>
          <App />
        </SocketProvider>
      </EventProvider>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
