import React, { useContext, useEffect, useState } from 'react'
import { MdGppGood } from 'react-icons/md'
import { useLocation, useNavigate, useHistory, useParams } from 'react-router-dom';
import ProtectedAxios from '../api/protectedAxios';
import { UserContext } from '../context/UserProvider';
import { Helmet } from 'react-helmet';

const UpgradeSucceeded = () => {
  const [time, setTime] = useState(5);
  const navigate = useNavigate()

  setTimeout(() => {
    if (time === 0) {
      navigate('/')
    } else {
      setTime(time - 1)
    }
  }, 1000)


  return (
    <div className='container box'>
      <Helmet>
        <title>Success - SageCollab</title>
      </Helmet>

      <div className='flexed-container column'>
        <MdGppGood className='declined-icon text-success' />
        <h2>Upgrade Completed</h2>
        <p className='text-center'>Your plan have been successfully upgraded. Redirecting you back to Home page in {time}s...</p>
        {/* <button className="button button-main" onClick={() => { }}>Go Back</button> */}
      </div>
    </div>
  )
}

export default UpgradeSucceeded