import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import { toast } from 'react-hot-toast'
import { FiExternalLink } from "react-icons/fi"
import { formatDate } from '../utils/helper'
import { IoCheckmark, IoCopy } from 'react-icons/io5'
import { MdDelete } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import LoadingSkeleton from './LoadingSkeleton'
import { EventContext } from '../context/EventProvider'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const SavedPrompts = forwardRef((props, ref) => {
    const { eventData, emitEvent } = useContext(EventContext);
    const [user] = useContext(UserContext)
    const [savedPrompts, setSavedPrompts] = useState([])
    const [loadingPrompts, setLoadingPrompts] = useState(true)

    const [selectedPrompt, setSelectedPrompt] = useState('')
    const [deletingPrompt, setDeletingPrompt] = useState(false)

    const [readingMore, setReadingMore] = useState(false)

    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    useImperativeHandle(ref, () => ({
        savePrompt() {
            props.setSavingPrompt(true)
            ProtectedAxios.post('/users/addSavedPrompt', { prompt: props.prompt, user_id: user.user_id })
                .then(res => {
                    if (res.data) {
                        fetchSavedPrompts()
                        props.setSavingPrompt(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 500) {
                        props.setSavingPrompt(false)
                        toast.error(err.response.data.error)
                    }
                })
        }
    }))

    useEffect(() => {
        if (eventData?.eventName === "refreshSavedPrompts") {
            fetchSavedPrompts()
        }
    }, [eventData])

    useEffect(() => {
        fetchSavedPrompts()
    }, [])

    const fetchSavedPrompts = () => {
        setLoadingPrompts(true)
        ProtectedAxios.post('/users/getAllSavedPrompts', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setSavedPrompts(res.data)
                    setLoadingPrompts(false)
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    setLoadingPrompts(false)
                    toast.error(err.response.data.error)
                }
            })
    }

    const deleteSavedPrompts = _prompt => {
        setDeletingPrompt(true)
        ProtectedAxios.post('/users/deleteSavedPrompt', { user_id: user.user_id, saved_prompt_id: _prompt.saved_prompt_id })
            .then(res => {
                if (res.data) {
                    setDeletingPrompt(false)
                    fetchSavedPrompts()
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    setDeletingPrompt(false)
                    toast.error(err.response.data.error)
                }
            })
    }

    return (
        <div className='saved-prompts container py-5' style={{ marginTop: "5dvh" }}>
            <Helmet>
                <title>Saved Prompts - SageCollab</title>
            </Helmet>

            <div className='flexed-between mb-4 gap-2'>
                <h2 className='text-left w-100 title m-0'>Saved Prompts</h2>
                <NavLink to="/start-chat" className='button button-new-chat'>New Chat</NavLink>
            </div>
            <div className='d-flex justify-content-end align-items-end w-100'>
                <div />
                {/* <h4 className='p-0 m-0'>Saved Prompts</h4> */}
                <a href="https://prompts.sagecollab.com" target='_blank' className='icon-link'>explore more prompts <FiExternalLink /></a>
            </div>
            <div className='saved-prompts-list container'>
                {loadingPrompts
                    ?
                    <div className='d-flex flex-column gap-3'>
                        <LoadingSkeleton type='saved-prompt' />
                        <LoadingSkeleton type='saved-prompt' />
                        <LoadingSkeleton type='saved-prompt' />
                    </div>

                    :
                    <>
                        {savedPrompts.length === 0
                            ?
                            <div className='saved-prompt-list-item pt-4'>
                                You don't have any saved prompts.
                            </div>
                            :
                            <>
                                {savedPrompts.map((prompt, i) => {
                                    return (
                                        <div className={`saved-prompt-list-item ${(selectedPrompt.template_id === prompt.template_id && deletingPrompt) ? "deleting-saved-prompt" : ""}`} key={i}>
                                            <div className='saved-prompt-left'>
                                                <p className='saved-prompt'>
                                                    {selectedPrompt.template_id === prompt.template_id && readingMore
                                                        ?
                                                        <>
                                                            {prompt.prompt}&nbsp;&nbsp;&nbsp;<a className='link cursor-pointer' onClick={() => { setReadingMore(false) }}>read less</a>
                                                        </>

                                                        :
                                                        <div onClick={() => { setSelectedPrompt(prompt); setReadingMore(true) }}>
                                                            {prompt.prompt.substring(0, 200)}{prompt.prompt.length > 200 && <>.....<a className='link dark-link cursor-pointer' onClick={() => { setSelectedPrompt(prompt); setReadingMore(true) }}>read more</a></>}
                                                        </div>
                                                    }
                                                </p>
                                                <p className='save-time subtitle'>{formatDate(new Date(prompt.created_at))}</p>
                                            </div>
                                            <div className={`saved-prompt-right`}>
                                                <div className='edit-btn-container justify-content-end align-start px-2'>
                                                    <button type='button' className='edit-btn' title='copy' value={prompt.prompt} onClick={e => { setSelectedPrompt(prompt); navigator.clipboard.writeText(e.target.value).then(() => setCopied(true)) }}>
                                                        {/* <IoCopy className='edit-icon' style={{ fill: "#3a3a3a" }} /> */}
                                                        {copied && selectedPrompt?.template_id === prompt.template_id
                                                            ?
                                                            <IoCheckmark className='edit-icon text-success' />
                                                            :
                                                            <IoCopy className='edit-icon' style={{ fill: "#3a3a3a" }} />
                                                        }
                                                    </button>
                                                    <button type='button' className='edit-btn cursor-pointer' title='delete' value={JSON.stringify(prompt)} onClick={e => { setSelectedPrompt(JSON.parse(e.target.value)); deleteSavedPrompts(JSON.parse(e.target.value)) }} disabled={deletingPrompt}>
                                                        <RiDeleteBin5Line className='edit-icon' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </>
                }
            </div>
        </div >
    )
})

export default SavedPrompts