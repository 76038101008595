import React, { useState } from 'react'
import Axios from '../api/axios'
import toast from 'react-hot-toast'
import { Helmet } from 'react-helmet'

const AddUser = () => {
    const registrationDataInitState = {
        name: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: ''
    }
    const [registrationData, setRegistrationData] = useState(registrationDataInitState)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const register = e => {
        e.preventDefault()
        setLoading(true)
        setError('')

        if (registrationData.password !== registrationData.confirmPassword) {
            setError('Please add the same password in both fields')
            setLoading(false)
        }
        else if (registrationData.username.length < 3 || registrationData.username.length > 15) {
            setError('Username should be between 3 to 15 characters')
            setLoading(false)
        }
        else {
            Axios.post('/auth/register', registrationData)
                .then(res => {
                    if (res.data) {
                        toast.success(`User - "${registrationData.name}" successfully added`)
                    } else {
                        toast.error('Could not register you at the moment, please try again later')
                    }
                    setRegistrationData(registrationDataInitState)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    }
                    if (err.response.status === 400) {
                        setError(err.response.data.error)
                    }
                })
        }

    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Add Users - SageCollab</title>
            </Helmet>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '2vh' }}>
                <h2 className='text-left w-100 font-xl'>Add User</h2>
            </div>

            <form className='form-container pt-3' onSubmit={register}>
                <div className='input-grp'>
                    <label htmlFor="full-name-input">Full Name <span className='text-danger'>*</span></label>
                    <input type="text" required id="full-name-input" value={registrationData.name} onChange={e => setRegistrationData({ ...registrationData, name: e.target.value })} />
                </div>
                <div className='form-grp'>
                    <div className='input-grp'>
                        <label htmlFor="email-input">Email <span className='text-danger'>*</span></label>
                        <input type="email" required id="email-input" value={registrationData.email} onChange={e => setRegistrationData({ ...registrationData, email: e.target.value })} />
                    </div>
                    <div className='input-grp'>
                        <label htmlFor="username-input">Username <span className='text-danger'>*</span></label>
                        <input type="text" minLength={3} maxLength={15} required id="username-input" value={registrationData.username} onChange={e => setRegistrationData({ ...registrationData, username: e.target.value })} />
                    </div>
                </div>


                <div className='form-grp'>
                    <div className='input-grp'>
                        <label htmlFor="password-input">Password <span className='text-danger'>*</span></label>
                        <input type='password' id="password-input" required value={registrationData.password} onChange={e => setRegistrationData({ ...registrationData, password: e.target.value })} />
                    </div>
                    <div className='input-grp'>
                        <label htmlFor="confirm-password-input">Confirm Password <span className='text-danger'>*</span></label>
                        <input type='password' id="confirm-password-input" required value={registrationData.confirmPassword} onChange={e => setRegistrationData({ ...registrationData, confirmPassword: e.target.value })} />
                    </div>
                </div>

                <div className='btn-container mb-3' style={{ marginTop: "-1rem" }}>
                    <p className='subtitle text-danger'>{error}</p>
                    <button type="submit" className="button button-main" disabled={loading}>
                        {loading
                            ? <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>

                            : "Create"
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddUser