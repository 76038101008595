import React from 'react'

const RegistrationSuccess = () => {
    return (
        <>
            <div>Registration Success</div>
            <p>{'(heading)'}</p>
            <p>{'(message)'}</p>
            <p>{'(button to login page)'}</p>
        </>
    )
}

export default RegistrationSuccess