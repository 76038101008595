import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Axios from '../api/axios'
import toast from 'react-hot-toast'
import { Helmet } from 'react-helmet'

const User = () => {
    const [loading, setLoading] = useState(true)
    const [userDetails, setUserDetails] = useState(null)
    const { username } = useParams()

    useEffect(() => {
        getUserDetails()
    }, [])

    const getUserDetails = () => {
        if (username) {
            setLoading(true)
            Axios.get(`/users/getProfileDetail/${username}`)
                .then(res => {
                    setUserDetails(res.data)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false)
                    toast.error(err.response.data.error)
                })
        }
    }

    return (
        <>
            <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <Helmet>
                    <title>Profile - SageCollab</title>
                </Helmet>
                {loading
                    ?
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>

                    :
                    <div className='d-flex flex-column align-items-center gap-3'>
                        <Helmet>
                            <title>{userDetails.name} - SageCollab</title>
                        </Helmet>
                        <img
                            src={userDetails.profile_picture_src}
                            alt={userDetails.username}
                            className='profile-picture'
                        />
                        <p className='mt-3 font-l m-0'>{userDetails.name}</p>
                        <p className='text-secondary font-s'>{userDetails.username}</p>
                    </div>
                }
            </div>
        </>
    )
}

export default User