import React, { useContext, useEffect, useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentElement } from '@stripe/react-stripe-js'
import paymentSuccessGif from '../assets/payment-success-card-gif.gif'
import doneGif from '../assets/done-gif.gif'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import { toast } from 'react-hot-toast'

const SetupIntentForm = ({ socket, enterpriseSubscriptionAdded, setEnterpriseSubscriptionAdded }) => {
    const [user, setUser] = useContext(UserContext)
    const stripe = useStripe()
    const elements = useElements()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")

    const [setupSucceeded, setSetupSucceeded] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setIsProcessing(true)

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/setup-intent/succeeded`
            },
            redirect: "if_required",
        })

        if (error) {
            console.log(error);
            setSuccessMsg("")
            setErrorMsg(error.message)
        } else if (setupIntent && setupIntent.status === 'succeeded') {
            setErrorMsg("")
            setSetupSucceeded(true)
            console.log("setupIntent - ", setupIntent);
            addEnterpriseSubscription(setupIntent.payment_method)
        }
        else {
            setSuccessMsg("")
            setErrorMsg("Unexpected state")
        }

        setIsProcessing(false)
    }

    const addEnterpriseSubscription = (_payment_method_id) => {
        ProtectedAxios.post('/subscription/addEnterprisePlan', { customer_id: user.stripe_customer_id, user_id: user.user_id, payment_method_id: _payment_method_id, name: user.name, email: user.email })
            .then(res => {
                if (res.data) {
                    setEnterpriseSubscriptionAdded(true)
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    // useEffect(() => {
    //     socket.on('enterprise-subscription-added', (data) => {
    //         setTimeout(() => {
    //             setEnterpriseSubscriptionAdded(true)
    //         }, 3000)
    //     })
    // }, [socket])


    return (
        <>
            {!setupSucceeded
                ?
                <form id='enterprise-payment-form' onSubmit={handleSubmit}>
                    <div className='p-4'>
                        <PaymentElement />
                    </div>
                    <button type="submit" className='button mt-2' disabled={isProcessing}>
                        {isProcessing
                            ?
                            <>
                                Processing
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            :
                            'Save Payment Method'
                        }
                    </button>

                    {errorMsg.length > 0
                        &&
                        <p className='m-0 pt-4 text-danger'>{errorMsg}</p>
                    }
                    {successMsg.length > 0
                        &&
                        <p className='m-0 pt-4 text-success'>{successMsg}</p>
                    }
                </form>

                :
                <>
                    {!enterpriseSubscriptionAdded
                        ?
                        <div className='payment-success'>
                            <img src={paymentSuccessGif} className='payment-success-gif' />
                            <p>
                                Successfully saved payment details. Adding Enterprise subscription in your account
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </p>
                        </div>

                        : <div className='payment-success'>
                            <img src={doneGif} className='done-gif' />
                            <p>
                                Congratulations! you are now subscribed to SageCollab Enterprise.
                            </p>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default SetupIntentForm