import React from 'react'

const TemplateMarketplaceIcon = ({ fill, className, style }) => {
    return (
        <svg
            style={style}
            xmlns="http://www.w3.org/2000/svg"
            height="26"
            width="26"
            viewBox="0 0 12 12"
            fill="none"
            className='m-0'
        >
            <path
                fill={fill ? fill : "#000"}
                fillRule="evenodd"
                d="M7.208 1.5H2.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H8a2.509 2.509 0 0 1-.792-1H2.5v-1H7c0-.356.074-.694.208-1ZM2.5 5.5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3Zm0 1h3v3h-3v-3Zm6-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-1Zm0 1h1v3h-1v-3Z"
                clipRule="evenodd"
            />
            <path
                stroke={fill ? fill : "#000"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0.6}
                d="M8 2.5h1.5m0 0H11m-1.5 0V1m0 1.5V4"
            />
        </svg>
    )
}

export default TemplateMarketplaceIcon