import React from 'react';

const SwitchButton = ({ active, onActiveChange, loading, title, onClick }) => {
    return (
        <>
            <button
                type="button"
                className={`switch-button ${active && 'switch-button-active'}`}
                onClick={onClick}
                disabled={loading}
                title={title}
            >
                <div className={`switch-slider ${active && 'switch-slider-active'} ${loading && 'switch-slider-loading'}`} />
            </button>
        </>
    );
}

export default SwitchButton;
