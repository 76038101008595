import React, { useContext, useEffect, useState } from 'react'
import LoginForm from '../components/LoginForm'
import { UserContext } from '../context/UserProvider'
import { useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { Helmet } from "react-helmet"

const Login = () => {
  const [user] = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('redirectTo');
  const sessionExpired = urlParams.get('sessionExpired');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let mounted = false
  useEffect(() => {
    if (!mounted) {
      if (sessionExpired) {
        handleShow()
        mounted = true
      }
    }
  }, [sessionExpired])

  useEffect(() => {
    if (user.accessToken === "") {
      setLoading(false)
    } else {
      navigate('/')
    }
  }, [user])
  return (
    <div className='container box'>
      <Helmet>
        <title>Log In - SageCollab</title>
        <meta name="description" content="Log in to your SageCollab account to access your personalized dashboard and brings team collaboration features to generative AI." />
        <meta name="keywords" content="login, SageCollab, access account, team collaboration, collaboration, generative AI, generative AI collaboration" />
        <meta property="og:title" content="Log In - SageCollab" />
        <meta property="og:description" content="Log in to your SageCollab account to access your personalized dashboard and brings team collaboration features to generative AI." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.sagecollab.com/login" />
        <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Log In - SageCollab" />
        <meta name="twitter:description" content="Log in to your SageCollab account to access your personalized dashboard and brings team collaboration features to generative AI." />
        <meta name="twitter:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
      </Helmet>

      {!loading
        &&
        <>
          <LoginForm redirectTo={redirectTo} />
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Session Expired</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='container mt-2'>
                <p>Your session has expired. Please log in again to continue.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className='button' onClick={handleClose}>Login</button>
            </Modal.Footer>
          </Modal>
        </>
      }
    </div>
  )
}

export default Login