import React from 'react'

const BrokenLogo = ({ fill, className, style }) => {
    return (
        <svg style={style} className={className} xmlns="http://www.w3.org/2000/svg" width="287" height="270" viewBox="0 0 287 270" fill={fill}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M117.142 0L139.554 61.6143L129.324 87.8373L127.589 88.4694L122.738 75.1326L109.93 90.4052L99.0363 81.2587L95.5682 100.938L81.5962 98.4733L88.4153 117.22L75.069 122.081L90.3447 134.906L81.2056 145.804L97.6556 148.707L85.5 156V183L70.2461 205.421L80.6309 146.49L34.6431 201.33L18.998 188.194L74.1627 122.411L6.98515 146.876L0 127.673L80.6391 98.3045L10.2286 85.8816L13.775 65.7566L98.3442 80.6775L43.5477 34.6695L56.6756 19.0149L122.397 74.1957L97.9511 6.98942L117.142 0ZM176.158 30.2116L152.729 45.4844L158.941 10.2282L179.055 13.7769L176.158 30.2116ZM233.925 89.0852L207.814 97.2936L199.826 114.996L198.414 100.84L186.63 117.1L199.456 115.816L193.614 128.761L211.795 136.969L200.261 145.265L211.911 161.483L197.764 162.899L199.745 182.765L186.811 176.925L178.603 195.112L170.314 183.571L154.102 195.231L152.692 181.095L136.301 182.736L115.14 188.102L107.486 218.143L86.7762 235.507L84.4045 240.763L103.019 249.168L132.451 183.947L140.973 269.405L161.295 267.371L154.193 196.149L204.294 265.9L220.881 253.971L179.166 195.895L257.385 231.212L265.791 212.586L200.602 183.152L286.023 174.6L283.996 154.266L212.825 161.392L282.555 111.242L270.638 94.6502L212.567 136.414L233.925 89.0852Z" fill={fill} />
        </svg>
    )
}

export default BrokenLogo