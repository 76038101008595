import React, { useContext, useEffect, useState } from 'react'
import LoadingSkeleton from '../components/LoadingSkeleton'
import { UserContext } from '../context/UserProvider'
import Modal from 'react-bootstrap/Modal';
import { AiOutlineEye } from 'react-icons/ai';
import ProtectedAxios from '../api/protectedAxios';
import toast from 'react-hot-toast';
import { RiCheckLine } from 'react-icons/ri'
import { FcCancel } from "react-icons/fc";
import { Helmet } from 'react-helmet';


const ManagePublicTemplates = () => {
    const { user } = useContext(UserContext)

    const [query, setQuery] = useState("")
    const [searchedText, setSearchedText] = useState("")

    const [loadingTemplates, setLoadingTemplates] = useState(true)
    const [templates, setTemplates] = useState([])

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([])

    const [viewTemplateModal, setViewTemplateModal] = useState(false)
    const openViewTemplateModal = () => {
        setViewTemplateModal(true)
    }
    const closeViewTemplateModal = () => {
        setViewTemplateModal(false)
    }

    const [togglingTemplateStatus, setTogglingTemplateStatus] = useState(false)

    useEffect(() => {
        fetchPublicTemplates()
    }, [])

    const fetchPublicTemplates = () => {
        setLoadingTemplates(true)
        ProtectedAxios.get(`/admin/templates/public?query=${query}`,)
            .then(res => {
                if (res.data) {
                    setTemplates(res.data)
                    setLoadingTemplates(false)
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("could not fetch public templates at the moment, please try again later.")
                setLoadingTemplates(false)
            })
    }

    const searchTemplates = (e) => {
        e.preventDefault()
        setSearchedText(query)
        fetchPublicTemplates()
    }

    const toggleTemplateStatus = (template_id, index) => {
        setTogglingTemplateStatus(true)
        ProtectedAxios.post(`/admin/templates/toggleStatus`, { template_id })
            .then(res => {
                if (res.data) {
                    setTemplates(prev => {
                        const updated = [...prev]
                        updated[index].is_active = !updated[index].is_active
                        return updated
                    })
                    setTogglingTemplateStatus(false)
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("could not toggle template status at the moment, please try again later.")
                setTogglingTemplateStatus(false)
            })
    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Public Templates - SageCollab</title>
            </Helmet>

            <h2 className='title'>Manage Public Templates</h2>

            <form onSubmit={searchTemplates} className='form md p-0 m-0 mt-5'><input type="text" value={query} onChange={e => setQuery(e.target.value)} placeholder="search templates" /></form>
            <p className='text-secondary my-4'>{searchedText && `showing search results for '${searchedText}'`}</p>

            {loadingTemplates
                ?
                <div>
                    <div className='pt-4'>
                        <LoadingSkeleton type='template' />
                        <LoadingSkeleton type='template' />
                        <LoadingSkeleton type='template' />
                        <LoadingSkeleton type='template' />
                    </div>
                </div>

                :
                <div className='table-container my-5'>
                    <table className='templates-table w-100'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Created By</th>
                                <th>Categories</th>
                                <th>Marketplace-Visibility</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {templates.length === 0
                            ?
                            <tbody>
                                <tr>
                                    <td colSpan={7}>No templates found</td>
                                </tr>
                            </tbody>

                            :
                            <tbody>
                                {templates.map((template, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: "3rem" }}>{i + 1}</td>
                                            <td className='d-flex flex-column gap-2'>
                                                {template.name}
                                            </td>
                                            <td style={{ minWidth: '8rem' }}>{template.description}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-1 cursor-pointer">
                                                    <img src={template.user_ref.profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                                                    <p className="m-0">{template.user_ref.username}</p>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    template?.TemplateCategories?.map(category => category.category_ref.category.toLowerCase()).join(", ")
                                                }
                                            </td>
                                            <td>{template.is_active ? "Active" : "Hidden"}</td>
                                            <td>
                                                <div className='edit-btn-container justify-content-start align-start px-2'>
                                                    <button className='edit-btn' title={'View template'} onClick={(e) => { setSelectedTemplate(template); setSelectedTemplateStructure(JSON.parse(template.prompt)); openViewTemplateModal() }}><AiOutlineEye className='edit-icon' /></button>
                                                    <button className='edit-btn' title={template.is_active ? 'Hide from markeplace' : 'Show on marketplace'} onClick={(e) => { setSelectedTemplate(template); toggleTemplateStatus(template.template_id, i) }}>
                                                        {togglingTemplateStatus && template.template_id === selectedTemplate?.template_id
                                                            ?
                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>

                                                            :
                                                            template.is_active
                                                                ? <FcCancel className='edit-icon' />
                                                                : <RiCheckLine className='edit-icon accept-icon' />
                                                        }
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            }



            {/* View Template Modal */}
            <Modal show={viewTemplateModal} onHide={closeViewTemplateModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        View Template
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='h-100 overflow-scroll'>
                        <h5>Preview</h5>
                        <div className='form-container'>
                            {selectedTemplateStructure.map((element, i) => {
                                return (
                                    <div className={``} key={i} id={`element-item-${element.id}`}>

                                        {element.type === "text"
                                            &&
                                            <>
                                                <p>{selectedTemplateStructure[i].content}</p>
                                            </>
                                        }

                                        {element.type === "textarea"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>{selectedTemplateStructure[i].label}</label>
                                                <input
                                                    id={`input-${element.id}`}
                                                    value={selectedTemplateStructure[i]?.value}
                                                    onChange={e => {
                                                        setSelectedTemplateStructure(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].value = e.target.value
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }

                                        {element.type === "select"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                                                <select
                                                    className='form-select'
                                                    id={`input-${element.id}`}
                                                    value={selectedTemplateStructure[i]?.value}
                                                    onChange={e => {
                                                        setSelectedTemplateStructure(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].value = e.target.value
                                                            return updatedData
                                                        })
                                                    }}
                                                >
                                                    {element.options.map((option, option_key) => {
                                                        return (
                                                            <option
                                                                key={option_key}
                                                                value={option.value}
                                                            >
                                                                {option.key}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </>
                                        }

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ManagePublicTemplates