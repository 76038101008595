import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { BiErrorCircle } from 'react-icons/bi'
import { GrFormAdd } from 'react-icons/gr'
import { FaPaperPlane } from 'react-icons/fa'
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { EventContext } from '../context/EventProvider';
import { useNavigate } from 'react-router-dom';

const DeleteChat = ({ chat_id, prompt, chat_name, setFilteredChatList, viewingInChat = false }) => {
    const [user] = useContext(UserContext)
    const { eventData, emitEvent } = useContext(EventContext);

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); emitEvent({ eventName: "closeChatDropdown", timestamp: new Date() }); }
    const handleShow = () => setShow(true);

    const [deletingChat, setDeletingChat] = useState(false)

    const navigate = useNavigate()

    const deleteChat = (e) => {
        e.preventDefault()
        setDeletingChat(true)
        ProtectedAxios.post('/users/deleteChat', { chat_id })
            .then(res => {
                if (res.data) {
                    setDeletingChat(false)
                    toast.success("Chat deleted")

                    if (viewingInChat) {
                        navigate(-1)
                    }
                    else {
                        emitEvent({ eventName: "closeChatDropdown", timestamp: new Date() });
                        setFilteredChatList(prev => {
                            let updatedList = prev.filter(chat => chat.chat_id !== chat_id)
                            return updatedList
                        })
                    }

                    handleClose()
                }
            })
            .catch(err => {
                setDeletingChat(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error, { id: 'delete-error' })
                }
                if (err.response.status === 403) {
                    toast(err.response.data.error, { icon: <BiErrorCircle style={{ color: "red", fontSize: "calc(var(--size-xxl) * 1.3)" }} />, id: 'delete-error' })
                } else {
                    console.log(err);
                }
            })
    }


    return (
        <>
            {true
                ?
                <div
                    onClick={handleShow}
                >
                    Delete
                </div>
                :
                <button className='edit-btn' id='share-button' title='share access' onClick={e => { handleShow() }}><MdPersonAddAlt1 className='edit-icon dark-icon' /></button>
            }



            <Modal show={show} onHide={handleClose} centered size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Chat</Modal.Title>
                </Modal.Header>
                <form onSubmit={deleteChat}>
                    <Modal.Body>
                        <div className='py-3 px-2'>
                            <p>
                                <b>
                                    Are you sure you want to delete this chat?
                                </b>
                                <br />
                                {chat_name
                                    ? chat_name
                                    : <>{prompt.substring(0, 60)}{prompt.length > 60 && '...'}</>
                                }
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='button button-danger' disabled={deletingChat}>
                            {deletingChat
                                ?
                                <>
                                    Yes, Delete
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                :
                                "Yes, Delete"
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default DeleteChat