import React, { useContext, useEffect } from 'react'
import NewChat from '../components/NewChat'
import Tabs from '../components/Tabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserProvider'
import { Helmet } from 'react-helmet'

const StartChat = ({ tokens, updateTokens }) => {
    const [user] = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()

    if (!user?.user_id && location.pathname.includes("start-chat")) {
        navigate(`/login?redirectTo=${location.pathname}${location.search}`)
    } else {
        return (
            <div className='container py-5'>
                <Helmet>
                    <title>New Chat - SageCollab</title>
                </Helmet>
                
                <NewChat updateTokens={updateTokens} tokens={tokens} />
                {/* <Tabs /> */}
            </div>
        )
    }
}

export default StartChat