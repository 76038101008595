import React, { useContext, useEffect, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { RiCloseLine } from 'react-icons/ri'
import { GrFormAdd } from 'react-icons/gr'
import { FaPaperPlane } from 'react-icons/fa'
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg"
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';

const ShareTemplateAccess = ({ template_id, prompt, showingShareTemplateModalAsLink, is_shared }) => {
    const [user] = useContext(UserContext)
    const [subscriptionDetail] = useSubscriptionDetails()

    const [username, setUsername] = useState("")
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [users, setUsers] = useState([])
    const [showingShareTemplateModalingList, setShowingShareTemplateModalingList] = useState(false)

    const [accessList, setAccessList] = useState([])
    const [loadingAccessList, setLoadingAccessList] = useState(true)

    const [childAccounts, setChildAccounts] = useState([])
    const [sendingInvitesToAllChildAccounts, setSendingInvitesToAllChildAccounts] = useState(false)

    const [showingShareTemplateModal, setShowingShareTemplateModal] = useState(false);
    const handleClose = () => setShowingShareTemplateModal(false);
    const handleShow = () => setShowingShareTemplateModal(true);

    useEffect(() => {
        if (showingShareTemplateModal) {
            loadUsers()
        }
    }, [username])

    useEffect(() => {
        if (showingShareTemplateModal) {
            loadAccessList()
        }
    }, [showingShareTemplateModal])

    const loadUsers = () => {
        if (username.length > 0) {
            setShowingShareTemplateModalingList(true)
            setLoadingUsers(true)
            ProtectedAxios.post('/users/templates/fetchUsersByUsername', { username, user_id: user.user_id, template_id })
                .then(res => {
                    setUsers(res.data)
                    setLoadingUsers(false)
                })
                .catch(err => {
                    setLoadingUsers(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        } else {
            setShowingShareTemplateModalingList(false)
            setUsers([])
        }
    }
    const loadAccessList = () => {
        setLoadingAccessList(true)
        ProtectedAxios.post('/users/fetchTemplateAccessList', { template_id })
            .then(async (res) => {
                setAccessList(res.data)
                console.log('access list loaded - ', res.data);
                await fetchChildAccounts(res.data)

                setLoadingAccessList(false)
            })
            .catch(err => {
                setLoadingAccessList(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addInAccessList = (_user) => {
        ProtectedAxios.post('/users/templates/addInAccessList', { template_id, recipient_id: _user.recipient_id, name: user.name, prompt, profile_picture_src: user.profile_picture_src })
            .then(res => {
                if (res.data) {
                    setUsername("")
                    setShowingShareTemplateModalingList(false)
                    toast.success(`Template shared with ${_user.username}`)
                    loadAccessList()
                }
            })
            .catch(err => {
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }
    const removeFromAccessList = (user) => {
        ProtectedAxios.post('/users/templates/removeFomAccessList', { template_access_id: user.template_access_id })
            .then(res => {
                if (res.data) {
                    toast.success(`Access removed for ${user.username}`)
                    loadAccessList()
                }
            })
            .catch(err => {
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const sendInviteToAllChildAccounts = async () => {
        setSendingInvitesToAllChildAccounts(true)
        toast.loading(`Sending invites to child accounts (0/${childAccounts.length})`, { id: 'child-accounts-template-access' })
        childAccounts.forEach(async (_user, i) => {
            await ProtectedAxios.post('/users/templates/addInAccessList', { template_id, recipient_id: _user.user_id, name: _user.name, prompt, profile_picture_src: _user.profile_picture_src })
                .then(res => {
                    if (res.data) {
                        toast.loading(`Adding access for child accounts (${i + 1}/${childAccounts.length})`, { id: 'child-accounts-template-access' })
                    }
                })
                .catch(err => {
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })

            if (i + 1 === childAccounts.length) {
                //means loop finished
                toast.success(`Access added for child accounts (${i + 1}/${childAccounts.length})`, { id: 'child-accounts-template-access' })
                loadAccessList()
                setSendingInvitesToAllChildAccounts(false)
            }
        })
    }

    useEffect(() => {
        if (showingShareTemplateModal) {
            fetchChildAccounts(accessList)
        }
    }, [subscriptionDetail])
    const fetchChildAccounts = async (accessList) => {
        if (subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID) {
            //Means that the user is enterprise admin so fetch the child accounts
            await ProtectedAxios.post('/users/enterprise/childAccounts', { enterprise_admin_user_id: user.user_id })
                .then(res => {
                    if (res.data) {
                        console.log('child accounts loaded - ', res.data);
                        console.log('access list - ', accessList);
                        let filteredChildAccounts = res.data.filter(
                            (childAccount) =>
                                !accessList.some((accessAccount) => accessAccount.username === childAccount.username)
                        );
                        console.log('filtered child accounts - ', filteredChildAccounts);

                        setChildAccounts(filteredChildAccounts)
                    }
                })
                .catch(err => {
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        }
    }

    return (
        <>
            {!showingShareTemplateModalAsLink
                ?
                <button disabled={is_shared} className='edit-btn' id='share-button' title={is_shared ? "can't change access for shared templates" : 'share access'} onClick={e => { handleShow() }}><MdPersonAddAlt1 className='edit-icon' /></button>
                :
                <div onClick={() => handleShow()}>
                    Share Access
                </div>
            }



            <Modal show={showingShareTemplateModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Share Template Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='share-modal-body'>
                        <div className='input-with-icon position-relative'>
                            <input autoFocus={true} className='form-input' type="text" placeholder="search by username, name or email" value={username} onChange={e => setUsername(e.target.value)} />
                            {username.length
                                ?
                                <button type="button" className='button-icon edit-btn' title='clear' onClick={() => setUsername("")}><RiCloseLine className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                :
                                <></>
                            }
                            {showingShareTemplateModalingList
                                &&
                                <div className='list-dropdown'>
                                    {loadingUsers
                                        ?
                                        <div>
                                            Loading
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {users.length > 0
                                                ?
                                                <>
                                                    {users.map((user, i) => {
                                                        return (
                                                            <div key={i} className="list-item">
                                                                <div className='profile-pic' style={{ backgroundImage: `url(${user.profile_picture_src ? user.profile_picture_src : placeholderProfile})` }} />
                                                                <div className='list-item-body'>
                                                                    <p className='name'>{user.name}</p>
                                                                    <p className='username'>{user.username}</p>
                                                                </div>
                                                                <button type="button" className='edit-btn' title='add access' value={JSON.stringify(user)} onClick={e => addInAccessList(JSON.parse(e.target.value))}><GrFormAdd className='edit-icon reject-icon' /></button>
                                                            </div>
                                                        )
                                                    })}
                                                </>

                                                :
                                                <p className='d-flex m-0 mx-2'>No users found!</p>
                                            }
                                        </>
                                    }
                                </div>
                            }

                            {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID && childAccounts.length > 0
                                &&
                                <button className='button button-plain-text p-0 mt-3' disabled={sendingInvitesToAllChildAccounts} onClick={() => sendInviteToAllChildAccounts()}>
                                    {sendingInvitesToAllChildAccounts
                                        ?
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            Share access with all child accounts ({childAccounts.length})
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>

                                        :
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            Share access with all child accounts ({childAccounts.length})
                                        </div>
                                    }
                                </button>
                            }

                        </div>
                        <div className='access-list-container mt-0 pt-0'>
                            <h3>People with access</h3>
                            <div className='access-list'>
                                {loadingAccessList
                                    ?
                                    <>
                                        Loading
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>

                                    :
                                    <>
                                        {accessList.length === 0
                                            ? <p className='px-1 '>No access given</p>
                                            :
                                            <>
                                                {accessList.map((user, i) => {
                                                    return (
                                                        <div key={i} className="list-item">
                                                            <div className='profile-pic' style={{ backgroundImage: `url(${user.profile_picture_src ? user.profile_picture_src : placeholderProfile})` }} />
                                                            <div className='list-item-body'>
                                                                <p className='name'>{user.name}</p>
                                                                <p className='username'>{user.username}</p>
                                                            </div>
                                                            <button type="button" className='edit-btn' title='remove access' value={JSON.stringify(user)} onClick={e => removeFromAccessList(JSON.parse(e.target.value))}><RiCloseLine className='edit-icon reject-icon ' /></button>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' onClick={() => handleClose()}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShareTemplateAccess