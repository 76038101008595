import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { BiBlock } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';

const TransactionCancelled = () => {
  const [time, setTime] = useState(5);
  const navigate = useNavigate()

  setTimeout(() => {
    if (time === 0) {
      navigate('/')
    } else {
      setTime(time - 1)
    }
  }, 1000)

  return (
    <div className='container box'>
      <Helmet>
        <title>Cancelled - SageCollab</title>
      </Helmet>
      <div className='flexed-container column'>
        <BiBlock className='declined-icon' />
        <h2>Transaction Cancelled</h2>
        <p className='text-center'>You have cancelled the transaction. Redirecting you back to subscriptions page in {time}s...</p>
        {/* <button className="button button-main" onClick={() => { }}>Go Back</button> */}
      </div>
    </div>
  )
}

export default TransactionCancelled