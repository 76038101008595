import React, { useContext, useEffect, useState } from 'react'
import Chat from './Chat'



const SharedChatsPrompt = ({ updateTokens, tokens }) => {
  return (
    <Chat isViewingWithSharedAccess={true} tokens={tokens} />
  )
}

export default SharedChatsPrompt