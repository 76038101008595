import { useContext, useEffect, useState } from 'react'
import Chat from './Chat'
import { UserContext } from '../context/UserProvider'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import PageNotFound from './PageNotFound'

const OrganizationChatView = ({ updateTokens }) => {
  const [user] = useContext(UserContext)
  const [subscriptionDetail] = useSubscriptionDetails()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (subscriptionDetail?.price_id) {
      setLoading(false)
    }
  }, [subscriptionDetail])
  return (
    <>
      {loading
        ?
        <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
          <div className="spinner-border spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        :
        <>
          {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
            ?
            <Chat isEnterpriseAdmin={true} />

            : <PageNotFound />
          }
        </>
      }
    </>
  )
}

export default OrganizationChatView