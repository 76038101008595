import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { toast } from 'react-hot-toast'
import Modal from 'react-bootstrap/Modal';
import { NavLink, useLocation } from 'react-router-dom'
import { styleOptions, toneOptions } from '../utils/constants';

const Template = ({ textInput, setPrompt, placement, template_id, setTemplateId, setTemplate, handleTextareaChange, showNormalOnly = false, submit = null }) => {
    const [user] = useContext(UserContext)
    const [showingTemplateDropdown, setShowingTemplateDropdown] = useState(false)
    const [loadingTemplates, setLoadingTemplates] = useState(true)
    const [templates, setTemplates] = useState([])

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([])

    const [placeholders, setPlaceholders] = useState([])
    const [selectedStyle, setSelectedStyle] = useState('0')
    const [selectedTone, setSelectedTone] = useState('0')

    const [show, setShow] = useState(false)
    const handleClose = async () => {
        setShow(false);
        if (setTemplateId) {
            setTemplateId(null)
        }
        if (setSelectedTemplate) {
            setSelectedTemplate(null)
        }
    }
    const handleShow = () => setShow(true)

    const [constructingPrompt, setConstructingPrompt] = useState(false)
    const location = useLocation()

    const templateDropdown = useRef(null)
    useClickOutside(templateDropdown)

    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingTemplateDropdown(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const urlTemplateId = template_id || searchParams.get('templateId');
        if (urlTemplateId) {
            toast.loading('Loading template details', { id: 'template-from-url', duration: Infinity })
            ProtectedAxios.post('/users/fetchUserTemplateDetailsWithTemplateId', { user_id: user.user_id, template_id: urlTemplateId })
                .then(res => {
                    if (res.data) {
                        let templateData = res.data

                        let data = { template_id: templateData.template_id, name: templateData.name, description: templateData.description, showing_additional_options: templateData.showing_additional_options, type: templateData.type }
                        setSelectedTemplate(data)
                        // setTemplate(data)
                        setSelectedTemplateStructure(JSON.parse(templateData.prompt))
                        setShow(true)
                        toast.dismiss('template-from-url')
                    }
                })
                .catch(err => {
                    console.log(err);
                    setTemplate(null)

                    if (err.response.status === 401) {
                        toast.error(err.response.data.error, { id: 'template-from-url', duration: 4000 })
                        return
                    }
                    toast.error("Can't use this template at the moment, please try again later.", { id: 'template-from-url', duration: 4000 })
                })
        }
    }, [location, template_id])


    useEffect(() => {
        if (textInput.split(" ", 2).length === 1 && textInput.split(" ", 2)[0].substring(0, 1) === "/") {
            setShowingTemplateDropdown(true)
        }
        else {
            setShowingTemplateDropdown(false)
        }
    }, [textInput])
    useEffect(() => {
        window.addEventListener("keydown", e => {
            if (e.key === "Escape") {
                setShowingTemplateDropdown(false)
            }
        })
    }, [])

    useEffect(() => {
        if (textInput.split(" ", 2).length === 1 && textInput.split(" ", 2)[0].substring(0, 1) === "/") {
            fetchTemplates()
        }
    }, [textInput])
    const fetchTemplates = async () => {
        setLoadingTemplates(true)
        ProtectedAxios.post('/users/fetchTemplates', { user_id: user.user_id, templateSearchText: textInput.substring(1, textInput.length), showSharedTemplates: true, showNormalOnly })
            .then(res => {
                if (res.data) {
                    setTemplates(res.data)
                    setLoadingTemplates(false)
                    console.log("templates - ", res.data);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("could not fetch your templates at the moment, please try again later.")
                setLoadingTemplates(false)
            })
    }


    const constructPrompt = async (e) => {
        e.preventDefault()
        let constructedPrompt = selectedTemplateStructure.map(element => {
            if (element.type === "text") {
                return element.content
            }
            else if (element.type === "hidden-text") {
                return element.content
            }
            else if (element.type === "textarea") {
                return element.value
            }
            else if (element.type === "select") {
                return element.value
            }
        }).join(" ")

        //add style/tone if available
        if (selectedStyle !== '0' && selectedTone === '0') {
            constructedPrompt = (`Be ${selectedStyle}.\n\n`) + constructedPrompt
        }
        else if (selectedStyle === '0' && selectedTone !== '0') {
            constructedPrompt = (`Your tone of response should be ${selectedTone}.\n\n`) + constructedPrompt
        }
        else if (selectedStyle !== '0' && selectedTone !== '0') {
            constructedPrompt = (`Be ${selectedStyle}. Your tone of response should be ${selectedTone}.\n\n`) + constructedPrompt
        }


        setSelectedStyle('0')
        setSelectedTone('0')

        setPrompt('')
        if (selectedTemplate.type !== "CHAT_STARTER_PRIVATE") {
            setPrompt(constructedPrompt)
        }

        setTimeout(() => {
            handleTextareaChange()
        }, 100)

        if (setTemplate) {
            setTemplate(selectedTemplate)
        }

        if (submit) {
            submit(null, null, constructedPrompt, selectedTemplate.template_id)
        }

        handleClose()
    }



    return (
        <>
            {showingTemplateDropdown
                &&
                <div ref={templateDropdown} className={`list-dropdown ${placement === 'top' ? 'top-placed' : ''}`}>
                    {loadingTemplates
                        ?
                        <div className='py-3 opacity-50'>
                            <div className="d-flex m-auto spinner-border spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>

                        :
                        <div className='list-container'>
                            {templates.length === 0
                                ?
                                <>
                                    <p className='pt-3 px-3'>
                                        No templates found. <NavLink to='/?activeTab=4&activeTemplateTab=1&createTemplate=true'>click here to add templates</NavLink>
                                    </p>
                                </>

                                :
                                <>
                                    {templates.map((template, key) => {
                                        return (
                                            <div className='list-item d-flex flex-wrap gap-3' key={key} onClick={() => { setSelectedTemplate(template); setSelectedTemplateStructure(JSON.parse(template.prompt)); handleShow() }}>
                                                {template.name}
                                                {template.is_shared
                                                    ?
                                                    <span className='shared-tag'>shared with you</span>

                                                    : ''
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    }
                </div>
            }

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title title={selectedTemplate?.name}>
                        {selectedTemplate?.name.substring(0, 30)}{selectedTemplate?.name.length > 30 && '...'}
                    </Modal.Title>
                </Modal.Header>
                {/* <form onSubmit={constructPrompt}> */}
                <Modal.Body>
                    <div className='container'>
                        <span className='text-secondary mt-3'>Description</span>
                        <p className='pt-2 pb-4'>{selectedTemplate?.description}</p>
                        <div className='form-container gap-3'>
                            {selectedTemplateStructure.map((element, i) => {
                                return (
                                    <div className={``} key={i} id={`element-item-${element.id}`}>

                                        {element.type === "textarea"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>{selectedTemplateStructure[i].label}</label>
                                                <input
                                                    id={`input-${element.id}`}
                                                    value={selectedTemplateStructure[i]?.value}
                                                    onChange={e => {
                                                        setSelectedTemplateStructure(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].value = e.target.value
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }

                                        {element.type === "select"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                                                <select
                                                    className='form-select'
                                                    id={`input-${element.id}`}
                                                    value={selectedTemplateStructure[i]?.value}
                                                    onChange={e => {
                                                        setSelectedTemplateStructure(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].value = e.target.value
                                                            return updatedData
                                                        })
                                                    }}
                                                    defaultValue={1}
                                                >
                                                    {element.options.map((option, option_key) => {
                                                        return (
                                                            <option
                                                                key={option_key}
                                                                value={option.value}
                                                            >
                                                                {option.key}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </>
                                        }

                                    </div>
                                )
                            })}
                            {selectedTemplate?.showing_additional_options == 1
                                &&
                                <>
                                    <div className='d-flex gap-4 flex-column flex-md-row'>
                                        <div className='input-grp'>
                                            <label htmlFor='style-select'>Style</label>
                                            <select id='style-select' className="form-select" value={selectedStyle} onChange={e => setSelectedStyle(e.target.value)}>
                                                <option value={0}>Default</option>
                                                {styleOptions.map((style, i) => {
                                                    return (
                                                        <option key={i} value={style}>{style}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='input-grp'>
                                            <label htmlFor='style-select'>Tone</label>
                                            <select id='style-select' className="form-select" value={selectedTone} onChange={e => setSelectedTone(e.target.value)}>
                                                <option value={0}>Default</option>
                                                {toneOptions.map((tone, i) => {
                                                    return (
                                                        <option key={i} value={tone}>{tone}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type='submit' className="button" disabled={constructingPrompt} onClick={constructPrompt}>
                        {constructingPrompt
                            ?
                            <>
                                Generate Response
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : ' Generate Response'
                        }
                    </button>
                </Modal.Footer>
                {/* </form> */}
            </Modal>
        </>
    )
}

export default Template