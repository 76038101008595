import React, { useEffect, useState } from 'react'
import ProtectedAxios from '../../api/protectedAxios'
import toast from 'react-hot-toast'
import moment from "moment-timezone"
import { Helmet } from 'react-helmet'


const AppStats = () => {
    const [loadingStats, setLoadingStats] = useState(true)
    const [stats, setStats] = useState({})
    const [fromDate, setFromDate] = useState(undefined)
    const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"))

    useEffect(() => {
        getAppStats()
    }, [fromDate, toDate])

    const getAppStats = () => {
        if (fromDate && toDate) {
            setLoadingStats(true)
            ProtectedAxios.post("/admin/appStats", { fromDate, toDate })
                .then(res => {
                    setLoadingStats(false)
                    setStats(res.data)
                })
                .catch(err => {
                    setLoadingStats(false)
                    console.log(err);
                    toast.error("Couldn't get app stats at the moment")
                })
        }
    }
    return (
        <div className='container py-5'>
            <Helmet>
                <title>App Stats - SageCollab</title>
            </Helmet>

            <h2>App Stats</h2>

            <div className='d-flex flex-wrap mt-5 mb-2 gap-3'>
                <div className='form md p-0 gap-0 align-items-start'>
                    <label htmlFor="start-date-input">From date</label>
                    <input type="date" id="start-date-input" value={fromDate} onChange={e => setFromDate(e.target.value)} />
                </div>
                <div className='form md p-0 gap-0 align-items-start'>
                    <label htmlFor="end-date-input">To date</label>
                    <input type="date" id="end-date-input" value={toDate} onChange={e => setToDate(e.target.value)} />
                </div>
            </div>

            <div>
                {!fromDate && toDate
                    ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <p className='text-secondary'>Select both date to load stats</p>
                    </div>

                    :
                    <div>
                        {loadingStats
                            ?
                            <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                                <div className="spinner-border spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>

                            :
                            <div className='my-4'>
                                <div className='table-container'>
                                    <table className='api-keys-table'>
                                        <thead>
                                            <tr>
                                                <th>New Users</th>
                                                <th>New Chats</th>
                                                <th>New Templates</th>
                                                <th>Unique Chat Creating User</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{stats.new_users}</td>
                                                <td>{stats.new_chats}</td>
                                                <td>{stats.new_templates}</td>
                                                <td>{stats.unique_chat_creating_users}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default AppStats