import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { BiErrorCircle } from 'react-icons/bi'
import { GrFormAdd } from 'react-icons/gr'
import { FaPaperPlane } from 'react-icons/fa'
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { EventContext } from '../context/EventProvider';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin5Line } from 'react-icons/ri';

const DeleteUserButton = ({ showAsIcon = true, fetchAllUsers, selectedUser }) => {
    const [user] = useContext(UserContext)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const [deletingUser, setDeletingUser] = useState(false)

    const deleteUserAccount = async (e) => {
        e.preventDefault()
        setDeletingUser(true)
        ProtectedAxios.post('/admin/deleteUser', { user_id: user.user_id, selected_user_id: selectedUser.user_id, stripe_customer_id: selectedUser.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    setDeletingUser(false)
                    toast.success(`account for user - '${selectedUser.name}' deleted successfully`)
                    fetchAllUsers()
                }
            })
            .catch(err => {
                setDeletingUser(false)
                console.log(err);
                toast.error("could not delete child account at the moment, please try again later.")
            })
    }

    return (
        <>
            {showAsIcon
                ?
                <button className='edit-btn' id='share-button' title='Delete User' onClick={e => { handleShow() }}><RiDeleteBin5Line className='edit-icon' /></button>
                :
                <div
                    onClick={handleShow}
                >
                    Delete User
                </div>
            }



            <Modal show={show} onHide={handleClose} centered size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <form onSubmit={deleteUserAccount}>
                    <Modal.Body>
                        <div className='px-2'>
                            <p>
                                <br />
                                Name: {selectedUser.name}
                                <br />
                                Username: {selectedUser.username}
                                <br />
                                Plan: {selectedUser.plan}
                                <br />
                                <br />
                                <b>
                                    Are you sure you want to delete this user?
                                </b>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='button button-danger' disabled={deletingUser}>
                            {deletingUser
                                ?
                                <>
                                    Yes, Delete
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                :
                                "Yes, Delete"
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default DeleteUserButton