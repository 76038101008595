import React, { useContext, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import { RiCoinsFill } from 'react-icons/ri'
import ProtectedAxios from '../api/protectedAxios'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import TokenIcon from './custom-svgs/TokenIcon'


const Tokens = ({ tokens = 0 }) => {
    const [user] = useContext(UserContext)
    const [creatingSession, setCreatingSession] = useState(false)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const createCheckoutSession = () => {
        setCreatingSession(true)
        ProtectedAxios.post('/users/createTopupSession', { user_id: user.user_id, customer_id: user.stripe_customer_id })
            .then(res => {
                window.location.href = res.data
                // setCreatingSession(false)
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                    setCreatingSession(false)
                }
            })
    }

    return (
        <div className='token'>
            <div className='token-container token-button' onClick={handleShow}>
                <TokenIcon fill='var(--color-golden)' />
                <p>
                    {parseInt(tokens).toLocaleString()}
                </p>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tokens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='token-offcanvas-body'>
                        <div className='token-container my-3'>
                            <h6>Available Tokens: </h6>
                            <TokenIcon fill='var(--color-golden)' />
                            <p>
                                {parseInt(tokens).toLocaleString()}
                            </p>
                        </div>
                        <div className='my-3 mt-5'>
                            <h6>Topup Options: </h6>
                            <div className='recharge-options-container'>
                                <div className='recharge-option selected-option'>
                                    <div className='recharge-option-header'>USD $12</div>
                                    <div className='token-container'>
                                        <span className='font-s'>+</span>
                                        <TokenIcon fill='var(--color-golden)' />
                                        <span className='font-s fw-medium'>
                                            {parseInt(3000000).toLocaleString()}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <p className='px-2 mt-4'>Tokens are for usage with the OpenAI model - GPT-3.5 Turbo.</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button" disabled={creatingSession} onClick={() => createCheckoutSession()}>
                        {creatingSession
                            ?
                            <>
                                Topup for $12
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : "Topup for $12"
                        }
                    </button>
                </Modal.Footer>
            </Modal>

            {/* <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='token-offcanvas-body'>
                        <h3>Tokens</h3>
                        <div className='token-container'>
                            Total Tokens 
                            <RiCoinsFill />
                            <p>
                                {parseInt(tokens).toLocaleString()}
                            </p>
                        </div>
                        <button onClick={() => createCheckoutSession()}>topup now</button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas> */}
        </div>
    )
}

export default Tokens