import { createContext, useState } from 'react'

export const SocketContext = createContext()

export const SocketProvider = (props) => {
    const [mySocket, setMySocket] = useState(null)

    return (
        <SocketContext.Provider value={[mySocket, setMySocket]}>
            {props.children}
        </SocketContext.Provider>
    )
}