import React, { useContext, useEffect, useState } from 'react';
import ProtectedAxios from '../../api/protectedAxios';
import { UserContext } from '../../context/UserProvider';
import toast from 'react-hot-toast';
import ReactApexChart from 'react-apexcharts';
import { Helmet } from 'react-helmet';

const UserStats = () => {
    const [user] = useContext(UserContext);
    const [interval, setInterval] = useState(5);
    const [data, setData] = useState([]);
    const [loadingDailyRegisteredUserStat, setLoadingDailyRegisteredUserStat] = useState(true);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    useEffect(() => {
        getDailyRegisteredUsers(); // Fetch initial data
    }, [interval]);

    const options = {
        chart: {
            type: 'line',
        },
        xaxis: {
            type: 'category',
            categories: data.map(item => { return `${new Date(item.date).getDate()} ${months[new Date(item.date).getMonth()]}` }), // Assuming 'date' is the category data
        },
        title: {
            text: "Daily Registered Users",
        },
    };

    const series = [
        {
            name: 'New Users',
            data: data.map(item => parseInt(item.total_users)),
        },
    ];

    const getDailyRegisteredUsers = () => {
        setLoadingDailyRegisteredUserStat(true);
        ProtectedAxios.post('/admin/dailyRegisterdUsers', { user_id: user.user_id, noOfDays: interval })
            .then(res => {
                // Sort the data by date in ascending order
                const sortedData = res.data.sort((a, b) => a.date.localeCompare(b.date));
                setData(sortedData);
                setLoadingDailyRegisteredUserStat(false);
            })
            .catch(err => {
                toast.error(err.response.data.error);
                setLoadingDailyRegisteredUserStat(false);
            });
    };

    return (
        <div className='container py-5'>
            <Helmet>
                <title>User Stats - SageCollab</title>
            </Helmet>
            <h2>User Stats</h2>
            <ReactApexChart className="mt-5" options={options} series={series} type='line' height={350} />
            <div className='d-flex gap-2 align-items-end justify-content-end'>
                <select className='form-select w-25' value={interval} onChange={e => setInterval(e.target.value)}>
                    <option value={5}>last 5 days</option>
                    <option value={10}>last 10 days</option>
                    <option value={15}>last 15 days</option>
                </select>
            </div>
        </div>
    );
};

export default UserStats;
