import React, { useContext, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Axios from '../api/axios'
import { UserContext } from '../context/UserProvider';
import { BsFillClockFill } from 'react-icons/bs'
import { BiBlock } from 'react-icons/bi'
import { FcExpired } from 'react-icons/fc'
import { RxCross2 } from 'react-icons/rx'
import { toast } from 'react-hot-toast'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { getProfilePicture } from '../utils/helper';
import { GoogleLogin } from '@react-oauth/google';


const LoginForm = ({ redirectTo = false }) => {
    const [user, setUser] = useContext(UserContext);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [error, setError] = useState('')
    const [needyVerificationStatus, setNeedyVerificationStatus] = useState(0)
    const [isActive, setIsActive] = useState(1)

    const [usingGoogleSignin, setUsingGoogleSignin] = useState(false)

    const navigate = useNavigate()

    const login = e => {
        e.preventDefault()
        setLoading(true)
        setError('')

        Axios.post('/auth/login', { username, password })
            .then(async (res) => {
                setLoading(false)
                if (res.data.role_id === 5) {
                    //user is enterprise-child so check if the admin stil has enterprise subscrition
                    Axios.post('/auth/validateEnterpriseSubscription', { enterprise_child_id: res.data.user_id })
                        .then(validateResponse => {
                            if (validateResponse.data.isEnterpriseSubscriptionActive) {
                                //means admin's enterprise subscription is active

                                //now check for if account is blocked
                                if (res.data.is_active === 0) {      //means user account has been blocked and can't login
                                    setIsActive(2)
                                } else {
                                    setUser(res.data)
                                    if (redirectTo) {
                                        navigate(redirectTo)
                                    } else {
                                        navigate('/')
                                    }
                                    toast.success('Logged in')
                                }
                            } else {
                                //means admin's enterprise subscription has expired
                                setIsActive(3)
                            }
                            setLoading(false)
                        })
                        .catch(err => {
                            console.log(err);
                            setLoading(false)
                            if (err.response.status === 404) {
                                toast.error(err.response.data.error)
                            } else {
                                toast.error('Could not log you in at the moment')
                            }
                        })
                }
                else {
                    if (res.data.is_active === 0) {      //means user account has been blocked and can't login
                        setIsActive(2)
                    } else {
                        setUser(res.data)
                        let updatedProfilePicture
                        if (redirectTo) {
                            navigate(redirectTo)
                        } else {
                            navigate('/')
                        }
                        toast.success('Logged in')
                        setLoading(false)
                    }
                }
                let updatedProfilePicture = await getProfilePicture(res.data.profile_picture_src)
                setUser(user => { return { ...user, profile_picture_src: updatedProfilePicture } })
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
                if (err.response.status === 400) {
                    setError(err.response.data.error)
                }
            })

    }

    const googleSignin = credential => {
        setUsingGoogleSignin(true)
        Axios.post("/auth/googleSignin", { credential })
            .then(res => {
                if (res.data.is_active === 0) {      //means user account has been blocked and can't login
                    setIsActive(2)
                } else {
                    setUser(res.data)
                    if (redirectTo) {
                        navigate(redirectTo)
                    } else {
                        navigate('/')
                    }
                    toast.success('Signin successful')
                }
                setUsingGoogleSignin(false)
            })
            .catch(err => {
                setUsingGoogleSignin(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    return (
        <>
            {isActive === 1
                ? <>
                    <div className='form md pt-0'>
                        <img style={{ width: "calc(var(--size-xxl) * 1.5)" }} src="./favicon.png" alt="logo" />
                        <h2 className='text-center font-m'>Welcome to SageCollab</h2>
                        {/* <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>Welcome back! Please log in to continue</p> */}

                        {redirectTo?.includes("/chat/shared/continue/")
                            &&
                            <div class="alert alert-primary font-xxs w-100 text-center">
                                Login to continue the shared chat.
                                <br />
                                <Link to={`/register${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>New user?</Link>
                            </div>
                        }
                        {redirectTo?.includes("/marketplace/template/")
                            &&
                            <div class="alert alert-primary font-xxs w-100 text-center">
                                Login to use the template. &nbsp;
                                <Link to={`/register${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>New user?</Link>
                            </div>
                        }
                        {redirectTo?.includes("/start-chat?templateId=")
                            &&
                            <div class="alert alert-primary font-xxs w-100 text-center">
                                Login to start chat with template. <br />
                                <Link to={`/register${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>New user?</Link>
                            </div>
                        }

                        <div className='mt-3 d-flex flex-column align-items-center gap-3'>
                            {usingGoogleSignin
                                ?
                                <div style={{ width: "227px", height: "44px", padding: "2px 10px" }}>
                                    <div className='border w-100 h-100 d-flex align-items-center justify-content-center'>
                                        <div className="dot-wrapper">
                                            <div className="dot dot1"></div>
                                            <div className="dot dot2"></div>
                                            <div className="dot dot3"></div>
                                            <div className="dot dot4"></div>
                                        </div>
                                    </div>
                                </div>

                                :
                                <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        googleSignin(credentialResponse.credential);
                                    }}
                                    onError={() => {
                                        toast.error("Could not log you in at the moment")
                                    }}
                                // useOneTap={true}
                                />
                            }

                        </div>

                        <div className='custom-divider'>
                            <span />
                            <p>or</p>
                            <span />
                        </div>

                        <form className='form-container' onSubmit={login}>
                            <div className='input-grp'>
                                <label htmlFor="username-email">Username <span className='text-danger'>*</span></label>
                                <input autoFocus type="text" id="username-email" value={username} onChange={e => setUsername(e.target.value)} />
                            </div>

                            <div className='input-grp'>
                                <label htmlFor="login-password">Password <span className='text-danger'>*</span></label>
                                <div className='input-with-icon'>
                                    <input required type={isPasswordVisible ? 'text' : 'password'} id='login-password' value={password} onChange={e => setPassword(e.target.value)} className='' />
                                    {isPasswordVisible
                                        ?
                                        <button type="button" className='button-icon edit-btn' title='hide password' onClick={() => { setIsPasswordVisible(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                        :
                                        <button type="button" className='button-icon edit-btn' title='view password' onClick={() => { setIsPasswordVisible(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                    }
                                </div>
                                <NavLink to="/send-password-reset-code" className="button-plain-text w-100 justify-content-end mt-2">Forgot password?</NavLink>
                            </div>
                            <div className='btn-container' style={{ marginTop: "-1rem" }}>
                                <p className='subtitle text-danger'>{error}</p>
                                <button type="submit" className="button button-main w-100" disabled={loading}>
                                    {loading
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : "Login"
                                    }
                                </button>
                                <NavLink to={`/register${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} className='text-center'>New to SageCollab? Create an account</NavLink>
                            </div>
                        </form>
                    </div>
                </>


                :
                <>
                    {isActive === 2
                        ?
                        //USER'S ACCOUNT BLOCKED
                        <div className='flexed-container column'>
                            <BiBlock className='declined-icon' />
                            <h2>Account Disabled</h2>
                            <p className='text-center'>We regret to inform you that your account has been temporarily disabled. You can no longer access your dashboard until your account is activated back again. Please contact with your administrator for further information and assistance.</p>
                            <button className="button button-main" onClick={() => setIsActive(1)}>Go Back</button>
                        </div>

                        :
                        //CHILD ACCOUNT TRYING TO LOGIN BUT THEIR ENTERPRISE ADMIN'S SUBSCRIPTION EXPIRE
                        <div className='flexed-container column'>
                            <FcExpired style={{ fontSize: '4rem' }} className='declined-icon' />
                            <h2>Subscription Expired</h2>
                            <p className='text-center'>We apologize for any inconvenience caused. It appears that your organization's Enterprise Subscription for SageCollab has expired. In order to continue enjoying uninterrupted access to our service, we kindly request you to contact your organization to initiate the subscription renewal process.</p>
                            <button className="button button-main" onClick={() => setIsActive(1)}>Go Back</button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default LoginForm