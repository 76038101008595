import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import ProtectedAxios from '../../api/protectedAxios';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { GiArmorUpgrade } from 'react-icons/gi'
import { toast } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import { FaFilter } from 'react-icons/fa'
import { UserContext } from '../../context/UserProvider';
import { Helmet } from "react-helmet"

const AddEnterpriseUser = () => {
  const [user] = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [addingUser, setAddingUser] = useState(false)
  const [addingConfirmedUser, setAddingConfirmedUser] = useState(false)
  const [previouslySentInviteId, setPreviouslySendInviteId] = useState('')
  const [userAdded, setUserAdded] = useState(false)

  const [loadingInvites, setLoadingInvites] = useState(false)
  const [invites, setInvites] = useState([])

  const [error, setError] = useState('mohamcsa is alreav skv splk.')
  const [successMsg, setSuccessMsg] = useState('mohamcsa is alreav skv splk.')

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setError('')
  }, [email])
  useEffect(() => {
    setSuccessMsg('')
  }, [email])

  useEffect(() => {
    fetchInvites()
  }, [])

  const fetchInvites = () => {
    setLoadingInvites(true)
    ProtectedAxios.post('/admin/fetchEnterpriseInvites', { user_id: user.user_id })
      .then(res => {
        if (res.data) {
          setInvites(res.data)
          setLoadingInvites(false)
        }
      })
      .catch(err => {
        setLoadingInvites(false)
        toast.error(JSON.stringify(err.response.data.error))
      })
  }

  const verifyEnterpriseInviteEmail = e => {
    e.preventDefault()
    setAddingUser(true)
    ProtectedAxios.post('/admin/verifyEnterpriseInviteEmail', { user_id: user.user_id, email })
      .then(res => {
        if (res.data.hasInviteAlreadySent) {
          setAddingUser(false)
          setPreviouslySendInviteId(res.data.enterprise_invites_id)
          handleShow()
        }
        else {
          sendEnterpriseInvite()
        }
      })
      .catch(err => {
        setAddingUser(false)
        if (err.response.status === 400 || err.response.status === 500) {
          setError(err.response.data.error)
        }
        else if (err.response.status === 500) {
          toast.error(err.response.data.error)
        }
        console.log(err);
      })
  }
  const sendEnterpriseInvite = () => {
    setAddingUser(true)
    setAddingConfirmedUser(true)
    ProtectedAxios.post('/admin/addEnterpriseInvite', { user_id: user.user_id, email, reSendingEmail: show, previouslySentInviteId })
      .then(res => {
        if (res.data) {
          setAddingConfirmedUser(false)
          setAddingUser(false)
          setUserAdded(true)
          fetchInvites()
          setSuccessMsg(`✅Invite for enterprise account has been sent to ${email}`)
          handleClose()
        }
      })
      .catch(err => {
        setAddingConfirmedUser(false)
        setAddingUser(false)
        toast.error(JSON.stringify(err.response.data.error))
      })
  }



  return (
    <div className='container py-5'>
      <Helmet>
        <title>Add Enterprise User - SageCollab</title>
      </Helmet>

      <h2>Add New Enterprise User</h2>
      <p className='subtitle'>Add an enterprise user by sending an invitation email. The recipient will receive a link to create their username and password and enroll in your enterprise plan.</p>

      <form className='enterprise-invite-form' onSubmit={verifyEnterpriseInviteEmail}>
        <div className='input-grp'>
          <label for='invitation-email'>Email</label>
          <input type='email' required id='invitation-email' value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className='btn-container'>
          {error.length > 0
            &&
            <p className='subtitle text-danger'>{error}</p>
          }
          {successMsg.length > 0
            &&
            <p className='subtitle text-success'>{successMsg}</p>
          }
          <button type="submit" className="button button-main my-3" disabled={addingUser}>
            {addingUser
              ?
              <>
                Send invite for Enterprise user
                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                  <span className="sr-only"></span>
                </div>
              </>

              : "Send invite for Enterprise user"
            }
          </button>
        </div>
      </form>
      {/* {JSON.stringify(invites)} */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Invite for enterprise account has already been sent to the email - '{email}' </p>
          <p>Do you want to send an invite again?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn-container justify-content-end flex-row'>
            <button className='button button-danger' onClick={handleClose}>Cancel</button>
            <button disabled={addingConfirmedUser} className='button' onClick={() => sendEnterpriseInvite()}>
              {addingConfirmedUser
                ?
                <>
                  Send invite again
                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </>

                : "Send invite again"
              }
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AddEnterpriseUser