import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import { formatDate } from '../utils/helper'
import ManageUserTable_Actions from './ManageUserTable_Actions'

const ManageUserTable_SubscriptionDetails = ({ customer_id, user_details, fetchAllUsers, setUsers }) => {
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)
    const [error, setError] = useState('')
    let isMounted = false

    useEffect(() => {
        if (!isMounted) {
            isMounted = true
            fetchUserSubscriptionDetails()
        }
    }, [customer_id])

    const fetchUserSubscriptionDetails = () => {
        setLoading(true)
        ProtectedAxios.post('/admin/UserSubscriptionDetails', { user_id: user.user_id, customer_id, iteratingUserId: user_details.user_id })
            .then(res => {
                setSubscriptionDetails(res.data)

                setUsers(prev => {
                    let updatedUsers = [...prev]
                    let index = updatedUsers.findIndex((item) => item.user_id === user_details.user_id)
                    updatedUsers[index] = { ...updatedUsers[index], user_plan: res.data.plan, openai_api_key: res.data.openai_api_key, gemini_api_key: res.data.gemini_api_key, azure_openai_api_key: res.data.azure_openai_api_key, azure_openai_endpoint: res.data.azure_openai_endpoint, azure_deployment_id: res.data.azure_deployment_id, azure_openai_model: res.data.azure_openai_model }
                    return updatedUsers
                })

                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err.response.data.error);
                setError(err.response.data.error)
            })
    }
    return (
        <>
            {loading
                ?
                <>
                    <td style={{ minWidth: '6rem', height: '3rem' }}>
                        <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </td>
                    <td style={{ minWidth: '6rem', height: '3rem' }}>
                        <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </td>
                    <td style={{ minWidth: '6rem', height: '3rem' }}>
                        <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </td>
                    <td style={{ minWidth: '6rem', height: '3rem' }}>
                        <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </td>
                    <td style={{ minWidth: '6rem', height: '3rem' }}>
                        <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </td>
                </>
                :
                <>
                    {error.length > 0
                        ? error
                        :
                        <>
                            <td>
                                {subscriptionDetails.plan}

                                {subscriptionDetails.status === "trialing"
                                    &&
                                    <>
                                        <br />
                                        <span className='shared-tag my-2' style={{ width: '6rem' }}>Trial</span>
                                        Ends - {subscriptionDetails.trial_end !== null && formatDate(new Date(subscriptionDetails.trial_end * 1000))}
                                    </>
                                }

                                {(subscriptionDetails.isOverridenSubscription && subscriptionDetails.plan_id === process.env.REACT_APP_PRICE_C_ID)
                                    &&
                                    <span className='shared-tag my-2' style={{ width: '6rem' }}>Free</span>
                                }

                            </td>

                            <ManageUserTable_Actions loading={loading} userDetails={{ ...user_details, ...subscriptionDetails }} fetchAllUsers={fetchAllUsers} fetchUserSubscriptionDetails={fetchUserSubscriptionDetails} />
                        </>
                    }
                </>
            }
        </>
    )
}

export default ManageUserTable_SubscriptionDetails