import toast from "react-hot-toast";
import ProtectedAxios from "../../api/protectedAxios";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserProvider";
import { MdRefresh } from "react-icons/md";

const LegacyTemplatesConverter = () => {
    const [user] = useContext(UserContext)
    const [legacyTemplates, setLegacyTemplates] = useState([])
    const [loadingLegacyTemplates, setLoadingLegacyTemplates] = useState(true)
    const [convertingLegacyTemplates, setConvertingLegacyTemplates] = useState(false)

    useEffect(() => {
        getLegacyTemplates()
    }, [])
    const getLegacyTemplates = () => {
        setLoadingLegacyTemplates(true)
        ProtectedAxios.post('/admin/getLegacyTemplates', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setLegacyTemplates(res.data)
                    setLoadingLegacyTemplates(false)
                }
            })
            .catch(err => {
                setLoadingLegacyTemplates(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    const legacyTemplateConverter = () => {
        setConvertingLegacyTemplates(true)
        ProtectedAxios.post('/admin/legacyTemplateConverter', { user_id: user.user_id, legacyTemplates: legacyTemplates })
            .then(res => {
                if (res.data) {
                    setConvertingLegacyTemplates(false)

                    toast.success("All legacy templates were updated to new format")

                    //re-check if there are still any legacy templates
                    getLegacyTemplates()
                }
            })
            .catch(err => {
                setConvertingLegacyTemplates(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    return (
        <>
            <h5 className="font-s">Legacy Template Converter</h5>
            {loadingLegacyTemplates
                ? <p className="pt-2">Checking for any legacy templates...</p>
                :
                <>
                    <div className="d-flex align-items-center">
                        <p className="m-0">Found {legacyTemplates.length} legacy templates.</p>
                        <button className='button-icon edit-btn' title='refresh' onClick={getLegacyTemplates}><MdRefresh className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                    </div>
                    {legacyTemplates.length > 0
                        &&
                        <button className="button button-ghost" disabled={convertingLegacyTemplates} onClick={legacyTemplateConverter}>
                            {convertingLegacyTemplates
                                ? "Converting..."
                                : "Update all to new templates"
                            }
                        </button>
                    }
                </>
            }
        </>
    )
}

export default LegacyTemplatesConverter