import { useContext, useEffect, useRef, useState } from "react"
import ProtectedAxios from "../api/protectedAxios"
import { toast } from "react-hot-toast"
import { MdArrowBack, MdCheck, MdDownload, MdSearch } from "react-icons/md"
import { BiCategoryAlt, BiDownArrowAlt, BiFilter } from "react-icons/bi"
import LoadingSkeleton from "../components/LoadingSkeleton"
import { UserContext } from "../context/UserProvider"
import { NavLink, useNavigate } from "react-router-dom"
import Modal from 'react-bootstrap/Modal'
import { GrFormClose } from "react-icons/gr"
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import { HiOutlineTemplate } from "react-icons/hi"
import { Helmet } from "react-helmet"
import MyDropdown from "../components/MyDropdown"
import { TbArrowsSort } from "react-icons/tb";

const TemplateMarketplace = ({ showHeader = true }) => {
  const [user] = useContext(UserContext)

  const [page, setPage] = useState(1)
  const [pageEnded, setPageEnded] = useState(false)
  const [fetchingTemplates, setFetchingTemplates] = useState(true)
  const [fetchingMoreTemplates, setFetchingMoreTemplates] = useState(false)
  const [templateSearchText, setTemplateSearchText] = useState("")
  const [typeFilter, setTypeFilter] = useState("all")
  const [templates, setTemplates] = useState([])

  const templatesContainerEndRef = useRef(null)

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [installingTemplate, setInstallingTemplate] = useState(false)

  const [searchingWithText, setSearchingWithText] = useState(false)
  const [searchText, setSearchText] = useState(false)

  const [isFavouriteFilterOn, setIsFavouriteFilterOn] = useState(false)

  const [selectedCategories, setSelectedCategories] = useState([])
  const [fetchingCategories, setFetchingCategories] = useState(false)
  const [categories, setCategories] = useState([])
  const [backupCategories, setBackupCategories] = useState([])
  const [searchCategoryText, setSearchCategoryText] = useState("")

  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false)

  const [templatePreviewModal, setTemplatePreviewModal] = useState(false)

  const [selectedSortOption, setSelectedSortOption] = useState("NEWEST_FIRST")

  const navigate = useNavigate()

  useEffect(() => {
    if (templateSearchText.length === 0) {
      fetchMarketplaceTemplates()
    }
  }, [templateSearchText])

  useEffect(() => {
    fetchMarketplaceTemplates()
  }, [isFavouriteFilterOn, typeFilter, selectedSortOption])

  const fetchMarketplaceTemplates = (e, _selectedCategory, _selectedSortOption) => {
    if (e) {
      e?.preventDefault()
    }

    setPageEnded(false)
    setPage(1)

    if (templateSearchText.length > 0) {
      setSearchingWithText(true)
      setSearchText(templateSearchText)
    } else {
      setSearchingWithText(false)
    }

    setFetchingTemplates(true)
    let selected_categories = _selectedCategory ? _selectedCategory : selectedCategories
    let sort_type = _selectedSortOption ? _selectedSortOption : selectedSortOption
    ProtectedAxios.post("/users/fetchMarketPlaceTemplates", { user_id: user.user_id, templateSearchText, selectedCategories: selected_categories, page: 1, favourite_filter: isFavouriteFilterOn, type_filter: typeFilter, sort_type })
      .then(res => {
        setTemplates(res.data)
        setFetchingTemplates(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Could not fetch marketplace templates at the moment please try again later.")
        setFetchingTemplates(false)
      })
  }


  const loadMoreMarketplaceTemplates = () => {
    setFetchingMoreTemplates(true)
    let updatedPage = page + 1
    setPage(page => page + 1)

    let selected_categories = selectedCategories
    ProtectedAxios.post("/users/fetchMarketPlaceTemplates", { user_id: user.user_id, templateSearchText, selectedCategories: selected_categories, page: updatedPage, favourite_filter: isFavouriteFilterOn, type_filter: typeFilter, sort_type: selectedSortOption })
      .then(res => {
        if (res.data.length < 4 || res.data.length === 0) {
          console.log("page ended");
          setPageEnded(true)
        }
        setTemplates(prev => [...prev, ...res.data])
        setFetchingMoreTemplates(false)
      })
      .catch(err => {
        console.log(err)
        toast.error(err.response.data.error || "Could not load templates please refresh the page")
        setFetchingMoreTemplates(false)
      })
  }


  useEffect(() => {
    if (categoryDropdownVisible) {
      fetchMarketPlaceCategories()
    }
  }, [categoryDropdownVisible])

  const fetchMarketPlaceCategories = () => {
    setFetchingCategories(true)
    ProtectedAxios.post("/users/fetchMarketPlaceCategories", { selectedCategories, templateSearchText, type_filter: typeFilter })
      .then(res => {
        setCategories(res.data)
        setBackupCategories(res.data)
        setFetchingCategories(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Could not fetch categories at the moment please try again later.")
        setFetchingCategories(false)
      })
  }

  const installTemplate = (_template) => {
    if (user?.user_id) {
      setInstallingTemplate(true)
      const startTime = performance.now()
      ProtectedAxios.post("/users/installTemplate", { user_id: user.user_id, selectedTemplate: _template })
        .then(res => {
          if (res.data) {
            const endTime = performance.now()
            let timeTaken = endTime - startTime
            let waitingTime = 3000 - timeTaken

            setTimeout(() => {
              //update to current template in templates list and set it to "installed"
              let indexOfSelectedTemplate = templates.findIndex(template => template.template_id === _template.template_id);

              setTemplates(prev => {
                let updatedTemplates = [...prev];
                updatedTemplates[indexOfSelectedTemplate] = { ..._template, installed: 1, download_count: res.data.download_count };
                return updatedTemplates; // Changed "updateTemplate" to "updatedTemplates"
              });

              setInstallingTemplate(false)
            }, waitingTime)
          }
        })
        .catch(err => {
          console.log(err);
          setInstallingTemplate(false)
          toast.error(err.response.data.error || err.response.statusText || "Could not install templates at the moment, please try again later")
        })

    } else {
      toast('Login/signup to continue')
      navigate(`/login?redirectTo=/marketplace/template/${_template?.template_id}`)
    }
  }

  const selectCategory = (_category) => {
    //check if the category has already been selected
    let indexOfSelectedCategory = selectedCategories.findIndex(category => category.category_id === _category.category_id)

    if (indexOfSelectedCategory === -1) {
      setSearchCategoryText("")
      setSelectedCategories(prev => {
        let updatedCategories = [...prev]
        updatedCategories.push(_category)
        fetchMarketplaceTemplates(undefined, updatedCategories)
        return updatedCategories
      })
    }
  }

  const removeCategory = (_category) => {
    const filteredCategories = selectedCategories.filter(category => category.category_id !== _category.category_id)
    setSelectedCategories(filteredCategories)
    fetchMarketplaceTemplates(undefined, filteredCategories)
  }

  useEffect(() => {
    filterCategoryList()
  }, [searchCategoryText])
  const filterCategoryList = () => {
    if (searchCategoryText.length > 0) {
      const updatedList = backupCategories.filter(category => {
        return category.category.toLowerCase().includes(searchCategoryText.toLowerCase())
      })
      setCategories(updatedList)
    } else {
      setCategories(backupCategories)
    }
  }

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadMoreMarketplaceTemplates()
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    const loadMoreButton = templatesContainerEndRef.current;

    if (loadMoreButton) {
      observer.observe(loadMoreButton);
    }

    return () => {
      if (loadMoreButton) {
        observer.unobserve(loadMoreButton);
      }
    };
  }, [fetchingTemplates, fetchingMoreTemplates]);

  const [togglingFavourite, setTogglingFavourite] = useState(false)
  const toggleFavourite = (currentState, template_id) => {
    setTogglingFavourite(true)

    if (currentState === 0) {
      ProtectedAxios.post("/users/addMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
        .then(res => {
          if (res.data) {
            setTemplates(prev => {
              let updatedTemplate = [...prev]
              const index = updatedTemplate.findIndex(template => template.template_id === template_id)
              updatedTemplate[index].is_favourite = 1
              return updatedTemplate
            })
            setTogglingFavourite(false)
          }
        })
        .catch(err => {
          setTogglingFavourite(false)
          console.log(err);
          toast.error(err.response.data.error || err.response.statusText || "Could not add template as favourite at the moment, please try again later")
        })
    } else {
      ProtectedAxios.post("/users/removeMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
        .then(res => {
          if (res.data) {
            if (isFavouriteFilterOn) {
              setTemplates(prev => {
                let updatedTemplate = [...prev]
                updatedTemplate = updatedTemplate.filter(template => template.template_id !== template_id)
                return updatedTemplate
              })
            }
            else {
              setTemplates(prev => {
                let updatedTemplate = [...prev]
                const index = updatedTemplate.findIndex(template => template.template_id === template_id)
                updatedTemplate[index].is_favourite = 0
                return updatedTemplate
              })
            }
            setTogglingFavourite(false)
          }
        })
        .catch(err => {
          setTogglingFavourite(false)
          console.log(err);
          toast.error(err.response.data.error || err.response.statusText || "Could not remove template as favourite at the moment, please try again later")
        })
    }

  }

  const categoryFilterDropdownRef = useRef(null)

  useClickOutside(categoryFilterDropdownRef)

  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCategoryDropdownVisible(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleTabClick = (tab) => {
    setTypeFilter(tab);

    // Get the clicked tab button element
    const tabButton = document.querySelector(`.tab-button-${tab}`);

    // Get the sliding background element
    const activeTabBackground = document.querySelector('#active-tab-background');

    // Update the position and width of the sliding background
    activeTabBackground.style.left = `${tabButton.offsetLeft}px`;
    activeTabBackground.style.width = `${tabButton.offsetWidth}px`;
  };

  useEffect(() => {
    // Get the clicked tab button element
    const tabButton = document.querySelector(`.tab-button-${typeFilter}`);

    // Get the sliding background element
    const activeTabBackground = document.querySelector('#active-tab-background');

    if (tabButton && activeTabBackground) {
      // Update the position and width of the sliding background
      activeTabBackground.style.left = `${tabButton.offsetLeft}px`;
      activeTabBackground.style.width = `${tabButton.offsetWidth}px`;
    }

  }, [])

  return (
    <div className='container py-5 mb-5'>
      {user?.user_id
        ?
        showHeader
        &&
        <div className="d-flex align-items-center gap-2">
          <Helmet>
            <title>Template Marketplace - SageCollab</title>
          </Helmet>
          <button type="submit" className='button-icon edit-btn' title='back' onClick={() => navigate(-1)}><MdArrowBack className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
          <h2 className="m-0">Template Marketplace</h2>
        </div>

        :
        <div>
          <Helmet>
            <title>Home - SageCollab</title>
            <meta name="description" content="SageCollab brings team collaboration features to generative AI." />
            <meta name="keywords" content="SageCollab, sagecollab app, team collaboration, collaboration, generative AI, generative AI collaboration" />
            <meta property="og:title" content="Home - SageCollab" />
            <meta property="og:description" content="SageCollab brings team collaboration features to generative AI." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://app.sagecollab.com" />
            <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Home - SageCollab" />
            <meta name="twitter:description" content="SageCollab brings team collaboration features to generative AI." />
            <meta name="twitter:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
          </Helmet>
          <h1 className=" font-l">SageCollab brings team collaboration features to generative AI.</h1>
          <div className="mobile-signup-container">
            <NavLink to="/login" className="button button-ghost">Login</NavLink>
            <span />
            <NavLink to="/register" className="button">Register</NavLink>
          </div>
          <br />
          <p className="">Empower AI chats and knowledge management. Store, categorize, and search AI chat history; enrich with personalized notes, foster team collaboration, and harness the potential of prompt templates.</p>
          <h2 className="font-xs fw-medium">Sign up now and start using these prompt templates with your AI chat.</h2>
          <br />
        </div>
      }
      <div className='template-header'>
        <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
          <form className='input-with-icon w-100' onSubmit={fetchMarketplaceTemplates}>
            <input
              className='form-input'
              type="text"
              placeholder="search template"
              value={templateSearchText}
              onChange={e => setTemplateSearchText(e.target.value)}
            />
            {templateSearchText.length
              ?
              <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
              :
              <></>
            }
          </form>

          <div className={`category-select-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''} position-relative`}>
            <button className='edit-btn' onClick={() => setCategoryDropdownVisible(prev => !prev)}>
              <BiFilter className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} />
            </button>
            <div ref={categoryFilterDropdownRef} className={`searchable-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''}`}>
              <div className="dropdown-content">
                <div className="dropdown-header">
                  <div className='dropdown-title'>
                    <p>Filter by cateogry</p>
                  </div>
                  <input type='text' placeholder='search category' value={searchCategoryText} onChange={e => setSearchCategoryText(e.target.value)} />
                </div>

                <div className="category-list">
                  {
                    fetchingCategories
                      ?
                      <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <p className="font-xxs">
                          Loading categories
                        </p>
                      </div>
                      :
                      categories.length === 0
                        ?
                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                          <BiCategoryAlt className="fs-3" />
                          <p className="font-xxs">
                            Nothing found
                          </p>
                        </div>
                        :
                        <div className="dropdown-categories">
                          {
                            categories.map((category, i) => {
                              return (
                                <div key={i} onClick={() => { selectCategory(category); setCategoryDropdownVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                  {category.category}
                                </div>
                              )
                            })
                          }
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>

          {user?.user_id
            &&
            <button title={isFavouriteFilterOn ? 'show all' : 'show favourites'} className={`edit-btn button-favourite-filter-toggle ${isFavouriteFilterOn ? 'on' : ''}`} disabled={fetchingTemplates} onClick={() => { setIsFavouriteFilterOn(prev => !prev); }}>
              {isFavouriteFilterOn
                ? <AiFillStar className='edit-icon' style={{ width: 'var(--size-l)', color: 'var(--color-golden)' }} />
                : <AiOutlineStar className='edit-icon' style={{ width: 'var(--size-l)', color: 'var(--color-dark)' }} />
              }
            </button>
          }
        </div>
      </div>

      <div className="applied-filters">
        {searchingWithText && templateSearchText.length > 0
          &&
          <span className={`tag tag-search editing`}>search: {searchText} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { setTemplateSearchText(""); setSearchText(""); fetchMarketplaceTemplates() }}><GrFormClose className={`edit-icon`} /></button></span>
        }
        {selectedCategories.length > 0
          &&
          selectedCategories.map((category, i) => {
            return (
              <span key={i} className={`tag tag-color-${i % 4 + 1} editing`}>{category.category} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeCategory(category) }}><GrFormClose className={`edit-icon`} /></button></span>
            )
          })
        }
      </div>

      <div className="d-flex flex-column justify-content-center justify-content-md-end align-items-end gap-4 mb-4">
        <div className="custom-tabs">
          <div id="active-tab-background"></div>
          {['all', 'official', 'community'].map((tab) => (
            <button
              key={tab}
              className={`tab-button tab-button-${tab}  ${typeFilter === tab ? 'active-tab' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <div className="px-4">
          <MyDropdown
            disabled={templates.length === 0}
            position='left'
            dropdownButton={
              <div className='dropdown-link sort-dropdown dropdown'>
                <button
                  disabled={templates.length === 0}
                  className='dropdown-toggle gap-1'
                >
                  <TbArrowsSort className="w-l h-l mx-2" />
                  {selectedSortOption === "NEWEST_FIRST"
                    ? "Newest"
                    :
                    selectedSortOption === "OLDEST_FIRST"
                      ? "Oldest"
                      :
                      selectedSortOption === "MOST_INSTALLED"
                        ? "Most Installed"
                        : "Least Installed"
                  }
                </button>
              </div>
            }
            title="Sort Templates"
          >
            <div className={`dropdown-item ${selectedSortOption === "NEWEST_FIRST" ? "active" : ""}`} onClick={() => { setSelectedSortOption("NEWEST_FIRST") }}>Newest First</div>
            <div className={`dropdown-item ${selectedSortOption === "OLDEST_FIRST" ? "active" : ""}`} onClick={() => { setSelectedSortOption("OLDEST_FIRST") }}>Oldest First</div>
            <div className={`dropdown-item ${selectedSortOption === "MOST_INSTALLED" ? "active" : ""}`} onClick={() => { setSelectedSortOption("MOST_INSTALLED") }}>Most Installed</div>
            <div className={`dropdown-item ${selectedSortOption === "LEAST_INSTALLED" ? "active" : ""}`} onClick={() => { setSelectedSortOption("LEAST_INSTALLED") }}>Least Installed</div>
          </MyDropdown>
        </div>
      </div>


      {fetchingTemplates
        ?
        <div className="prompt-list-container">
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
        </div>

        :
        templates.length === 0
          ?
          <div className="template-container d-flex gap-1 justify-content-center align-items-center flex-column">
            <HiOutlineTemplate className="fs-3" />
            <p>
              No templates found
            </p>
          </div>

          :
          <div className='template-container'>
            {templates.map((template, i) => {
              return (
                <div key={i} className="marketplace-template">
                  <div className="progress-container"><span className={`my-progress ${selectedTemplate?.template_id === template.template_id && installingTemplate ? 'on' : ''}`} /></div>
                  {user?.user_id
                    &&
                    <button disabled={togglingFavourite && selectedTemplate?.template_id === template.template_id} className={`button-favourite edit-btn ${togglingFavourite && selectedTemplate?.template_id === template.template_id ? 'toggling' : ''}`} onClick={() => { toggleFavourite(template.is_favourite, template.template_id); setSelectedTemplate(template) }}>
                      {template.is_favourite
                        ?
                        <AiFillStar className="favourite-icon" />
                        :
                        <AiOutlineStar className="not-favourite-icon" />
                      }
                    </button>
                  }
                  <div className="template-body mt-1">
                    <div>
                      <NavLink className="template-name hover-underline" to={`/marketplace/template/${template.template_id}`}>
                        {template.installed === 1
                          &&
                          <span style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1 installed-tag"><MdCheck fill="green" />Installed</span>
                        }
                        {template.name}
                      </NavLink>
                      <div className="company-container">
                        <p className="company-name">
                          {/* By&nbsp; */}
                          {(template.created_user_role_id === 1 || template?.company_url)
                            ?
                            <a href={template.company_url} target="_blank">{template.company_name}</a>

                            :
                            <div className="d-flex align-items-center gap-1 cursor-pointer">
                              <img src={template.created_user_profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                              <NavLink to={`/user/${template.created_user_username}`} className="m-0">{template.created_user_username}</NavLink>
                            </div>
                          }
                        </p>
                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1">
                          <BiDownArrowAlt />
                          {template?.download_count?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="template-description" title={template.description}>{template.description.substring(0, 100)}{template.description.length > 100 && "..."}</div>
                    <div>
                      <div className="template-categories">
                        {template.selectedCategories.map((category, i) => {
                          return (
                            <>
                              <div key={i} className={`category `} onClick={() => { window.scrollTo(0, 0); selectCategory(category) }}>
                                {category.category}
                              </div>
                              &nbsp;
                            </>
                          )
                        })
                        }
                      </div>
                      <div className="template-footer">
                        <div />
                        <div className="d-flex gap-2 justify-content-end mt-">
                          {user?.user_id
                            // ? <button onClick={() => navigate(`/start-chat?templateId=${template.template_id}`)}>{template.installed ? 'Use' : 'Try'}</button>
                            ? <NavLink to={`/start-chat?templateId=${template.template_id}`} className="button button-border bg-transparent">{template.installed ? 'Use' : 'Try'}</NavLink>
                            : <NavLink to={`/marketplace/template/${template.template_id}`} className="button button-border bg-transparent">View</NavLink>
                          }
                          {/* {user?.user_id
                            &&
                            <button className={`button button-border`} onClick={() => navigate(`/start-chat?templateId=${template.template_id}`)}>{template.installed ? 'Use' : 'Try'}</button>
                          } */}
                          <button className={`button ${template.installed === 1 ? "button-borde" : ""}`} onClick={() => { setSelectedTemplate(template); installTemplate(template) }} disabled={selectedTemplate?.template_id === template.template_id && installingTemplate}>
                            {selectedTemplate?.template_id === template.template_id && installingTemplate
                              ?
                              <>
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </>

                              :
                              template.installed === 0
                                ? "Install"
                                : "Install Again"
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {!pageEnded
              ?
              fetchingMoreTemplates
                ?
                <div className='prompt-list-container m-0 mt-2'>
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                </div>

                :
                <div ref={templatesContainerEndRef} />
              : ""
            }
          </div >
      }


      {/* View Template Modal */}
      < Modal show={templatePreviewModal} onHide={() => setTemplatePreviewModal(false)} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='h-100 overflow-scroll marketplace-template'>
            <div>
              <div className="template-name">
                {selectedTemplate?.installed === 1
                  &&
                  <span style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1 installed-tag"><MdCheck fill="green" />Installed</span>
                }
                {selectedTemplate?.name}
              </div>
              <div className="company-container">
                <p className="company-name">
                  {/* By&nbsp; */}
                  {(selectedTemplate?.created_user_role_id === 1 || selectedTemplate?.company_url)
                    ?
                    <a href={selectedTemplate?.company_url} target="_blank">{selectedTemplate?.company_name}</a>

                    :
                    <div className="d-flex align-items-center gap-1 cursor-pointer">
                      <img src={selectedTemplate?.created_user_profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                      <p className="m-0">{selectedTemplate?.created_user_username}</p>
                    </div>
                  }
                </p>
                <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1">
                  <BiDownArrowAlt />
                  {selectedTemplate?.download_count?.toLocaleString()}
                </div>
              </div>
            </div>
            <p className="template-description">{selectedTemplate?.description}</p>

            <div className="template-categories">
              {selectedTemplate?.selectedCategories.map((category, i) => {
                return (
                  <>
                    <div key={i} className={`category `}>
                      {category.category}
                    </div>
                    &nbsp;
                  </>
                )
              })
              }
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </div >
  )
}

export default TemplateMarketplace