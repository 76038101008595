import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import LoadingSkeleton from './LoadingSkeleton'
import { TbNotes } from 'react-icons/tb'
import { UserContext } from '../context/UserProvider'
import { SocketContext } from '../context/SocketProvider'
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { calculateDateDistance, formatDate } from '../utils/helper'

const Notes = ({ chat_id, response_id, isEnterpriseAdmin }) => {
    const [user] = useContext(UserContext)
    const [mySocket] = useContext(SocketContext)

    const [notes, setNotes] = useState([])
    const [loading, setLoading] = useState(true)

    const [noteText, setNoteText] = useState('')
    const [addNote, setAddNote] = useState(false)
    const [addingNotes, setAddingNotes] = useState(false)

    useEffect(() => {
        if (mySocket) {
            mySocket.on('note_added', (data) => {
                console.log('note_added - triggered');
                fetchNotes(false)
            })
        }
    }, [])

    let mounted = false
    useEffect(() => {
        if (!mounted) {
            fetchNotes()
            mounted = true
        }
    }, [])
    const fetchNotes = (_load = true) => {
        if (_load) {
            setLoading(true)
        }
        ProtectedAxios.post("/users/notes", { response_id })
            .then(res => {
                setNotes(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    const addNoteForResponse = e => {
        if (noteText.length > 0) {
            setAddingNotes(true)
            ProtectedAxios.post("/users/addNote", { chat_id, response_id, note_content: noteText, user_id: user.user_id })
                .then(res => {
                    let updatedNotes = notes
                    updatedNotes.push(res.data)
                    setNotes(updatedNotes)
                    setAddNote(false)
                    setNoteText("")
                    setAddingNotes(false)
                })
                .catch(err => {
                    console.log(err);
                    setAddingNotes(false)
                })
        }
    }

    return (
        loading
            ?
            <>
                <LoadingSkeleton type="rectangle-half" />
                <LoadingSkeleton type="rectangle-half" />
            </>

            :
            <>
                <div className={`notes ${isEnterpriseAdmin ? 'border-0' : ""}`}>
                    {notes.map((note, i) => {
                        return (
                            <div className='note-container' key={i}>
                                <div className='note-header'>
                                    <h5>
                                        <img className='profile-image w-xxl h-xxl' src={`${note.profile_picture_src ? `${note.profile_picture_src}` : placeholderProfile}`} />
                                        {note.name}
                                    </h5>
                                    <p className='date'>{calculateDateDistance(new Date(note.created_at))}</p>
                                </div>
                                <p className='note-content'>{note.note_content}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='w-100 d-flex justify-content-end'>
                    {!addNote
                        ?
                        <>
                            {!isEnterpriseAdmin
                                &&
                                <button type='button' id='add-note-button' className='edit-btn noTag-addBtn add-note-button' title='add note' onClick={() => { setAddNote(true) }}><TbNotes className='edit-icon tag-icon' />Add Note</button>
                            }
                        </>

                        :
                        <div className='note-container note-form'>
                            <div className='note-header'>
                                <h5>Add Note</h5>
                            </div>
                            <textarea autoFocus={true} value={noteText} onChange={e => setNoteText(e.target.value)} />
                            <div className='btn-row-container'>
                                <a className='text-dark cursor-pointer text-decoration-none' onClick={() => setAddNote(false)}>cancel</a>
                                <button type='submit' className="button button-main" onClick={() => addNoteForResponse()} disabled={addingNotes || noteText.length === 0}>
                                    {addingNotes
                                        ?
                                        <>
                                            Add
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </>

                                        : "Add"
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </>
    )
}

export default Notes