import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { MdSpeakerNotesOff } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";
import { BsArrowRight, BsChatLeftText } from "react-icons/bs";
import { RiChatCheckFill } from "react-icons/ri";
import ProtectedAxios from '../api/protectedAxios';
import { UserContext } from '../context/UserProvider';
import toast from 'react-hot-toast';
import { BiArrowFromRight, BiArrowToRight } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const ContinueSharedChat = () => {
    const [user] = useContext(UserContext)
    const { chat_id } = useParams()

    const [loading, setLoading] = useState(true)
    const [chatDetails, setChatDetails] = useState('')

    const [steps, setSteps] = useState(1)

    const [copyNote, setCopyNote] = useState(true)

    const [addingChat, setAddingChat] = useState(false)
    const [isChatShared, setIsChatShared] = useState(false)
    const [newChatId, setNewChatId] = useState("")

    const navigate = useNavigate()



    useEffect(() => {
        fetchChatDetails()
    }, [chat_id])

    const fetchChatDetails = () => {
        setLoading(true)

        ProtectedAxios.post('/users/getSharedChatDetails', { chat_id, user_id: user.user_id })
            .then(res => {
                setChatDetails(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addSharedChat = () => {
        setSteps(2)
        setAddingChat(true)
        ProtectedAxios.post("/users/addSharedChat", { user_id: user.user_id, chat_id: parseInt(chat_id), copyNote })
            .then(res => {
                if (res.data) {
                    setNewChatId(res.data.new_chat_id)
                    setIsChatShared(true)
                }
                else {
                    setIsChatShared(false)
                }
                setAddingChat(false)
            })
            .catch(err => {
                console.log(err);
                setAddingChat(false)
                setIsChatShared(false)
                if (err.response.data.error) {
                    toast.error(err.response.data.error)
                }
            })
    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Continue Shared Chat - SageCollab</title>
            </Helmet>
            {loading
                ?
                <div className='d-flex flex-column gap-3 justify-content-center align-items-center' style={{ height: '60vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                chatDetails === "" || chatDetails.is_sharing === false
                    ?
                    <div className='d-flex flex-column align-items-center justify-content-center gap-3' style={{ height: '60vh' }}>
                        <MdSpeakerNotesOff className='w-xxl h-xxl' />
                        <p className='text-center'>This chat is now private or deleted, sharing is no longer available!</p>
                    </div>

                    :
                    steps === 1
                        ?
                        <div className='container d-flex flex-column gap-3 justify-content-start align-items-start pt-5' style={{ width: "40rem", maxWidth: "95vw" }}>
                            <h1 className='font-m'>Add shared chat to your account</h1>

                            <div className='d-flex w-100 align-items-center justify-content-between gap-3'>
                                <h2 className='font-s m-0'>
                                    {chatDetails.chat_name
                                        ? <>{chatDetails.chat_name}</>
                                        : <>{chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ").substring(0, 50)}{chatDetails.message_content.length > 50 && '...'}</>
                                    }
                                </h2>
                                {chatDetails.model_provider === "OPENAI"
                                    ?
                                    <div className='chat-select-item' title="Using OpenAI chat model">
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} />
                                            <p className='m-0 font-xs'>OpenAI</p>
                                        </div>
                                    </div>

                                    :
                                    <div className='chat-select-item' title="Using Gemini chat model">
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} />
                                            <p className='m-0 font-xs'>Gemini</p>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div>
                                <p>Created by {chatDetails.name}</p>
                                <p>This chat is currently shared with others. To keep a private copy and continue adding messages on your own, create a copy. This won't affect the original shared chat.</p>
                                <div className="form-check align-items-center d-flex gap-1">
                                    <input className="form-check-input" type="checkbox" checked={copyNote} id="notes-checkbox" onChange={e => setCopyNote(prev => !prev)} />
                                    <label className="form-check-label m-0" for="notes-checkbox">
                                        copy the notes on messages as well
                                    </label>
                                </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-between mt-2 w-100'>
                                <button className='bg-transparent border-0' onClick={() => navigate(-1)}>cancel</button>
                                <button className='button gap-2' onClick={addSharedChat}>Create copy <BsArrowRight className='w-xs h-xs' /></button>
                            </div>
                        </div>


                        :
                        addingChat
                            ?
                            <div className='d-flex flex-column gap-3 justify-content-center align-items-center' style={{ height: '60vh' }}>
                                <div className="spinner-border spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                <p>Adding the shared chat to your account</p>
                            </div>

                            :
                            !isChatShared
                                ?
                                <div className='d-flex flex-column align-items-center justify-content-center gap-3' style={{ height: '60vh' }}>
                                    <MdSpeakerNotesOff className='w-xxl h-xxl' />
                                    <p className='text-center'>This chat is now private or deleted, sharing is no longer available!</p>
                                </div>

                                :
                                <div className='d-flex flex-column align-items-center justify-content-center gap-4' style={{ height: '60vh' }}>
                                    <RiChatCheckFill className='w-xxl h-xxl text-success fill-destructive' />
                                    <div className='d-flex flex-column gap-3 align-items-center'>
                                        <p className='text-center m-0'>This shared chat has been added to your account. <br />You can now access it anytime.</p>
                                        <NavLink to={`/chat/${newChatId}`} className="button button-ghost">Go to chat</NavLink>
                                    </div>
                                </div>
            }

        </div>
    )
}

export default ContinueSharedChat