import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';

const Subscriptions = ({ closeModal }) => {
    const [user, setUser] = useContext(UserContext)
    const [subscriptions, setSubscriptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [processingFreePlan, setProcessingFreePlan] = useState(false)
    const [selectedPriceId, setSelectedPriceId] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        fetchSubscriptionsDetail()
    }, [])

    const fetchSubscriptionsDetail = () => {
        setLoading(true)
        ProtectedAxios.get('/users/fetchAllSubscriptions')
            .then(async (res) => {
                if (res.data) {
                    let plans = res.data
                    console.log(plans);
                    plans.sort((a, b) => a.unit_amount - b.unit_amount)
                    console.log(process.env.REACT_APP_PRICE_C_ID);
                    console.log(process.env.REACT_APP_PRICE_D_ID);
                    let filteredPlan = plans.filter(plan => plan.id !== process.env.REACT_APP_PRICE_C_ID)
                    filteredPlan = filteredPlan.filter(plan => plan.id !== process.env.REACT_APP_PRICE_D_ID)
                    console.log(filteredPlan);
                    setSubscriptions(filteredPlan)
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err);
            })
    }

    const createCheckoutSession = _priceId => {
        setSelectedPriceId(_priceId)
        const toastId = toast.loading('Redirecting to payment gateway...')
        setProcessing(true)
        ProtectedAxios.post('/subscription/create-checkout-session', { price_id: _priceId, customer_id: user.stripe_customer_id })
            .then(res => {
                window.location.href = res.data
                setProcessing(false)
                toast.remove(toastId)
            })
            .catch(err => {
                setProcessing(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const selectFreePlan = _priceId => {
        setProcessingFreePlan(true)
        ProtectedAxios.post('/subscription/addFreePlan', { user_id: user.user_id, price_id: _priceId, customer_id: user.stripe_customer_id })
            .then(res => {
                console.log(res.data.stripe_subscription_id);
                setUser({ ...user, stripe_subscription_id: res.data.stripe_subscription_id })
                setProcessingFreePlan(false)
                toast("Congratulations! You're now subscribed to our free plan", {
                    icon: "🎉"
                })
            })
            .catch(err => {
                console.log(err);
                setProcessingFreePlan(false)
            })
    }

    return (
        <div className='container py-5 subscription-main mt-0'>
            {loading
                ?
                <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                <div className='subscription-container mt-0'>
                    {subscriptions.length === 0
                        ? <p>No Subscription Found. Please refresh or try to login again later</p>

                        :
                        <>
                            {subscriptions.map((plan, i) => {
                                return (
                                    <div className={`subscription ${JSON.parse(plan.metadata.isRecommended) ? 'recommended' : ''}`} key={i}>
                                        <div className='subscription-header'>
                                            <div className='right'>
                                                <p className='name'>{plan.metadata.plan_name}</p>
                                                <p className='price'>{plan.currency.toUpperCase()} ${parseInt(plan.unit_amount) / 100} <span className='sub-text'>{plan.unit_amount > 0 && '/month'}</span></p>
                                            </div>
                                        </div>
                                        <div className='subscription-body'>
                                            <div className='features'>
                                                {JSON.parse(plan.metadata.offerings).map((offer, x) => {
                                                    return (
                                                        <p className='feature' key={x}>{offer.offering}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='subscription-footer'>
                                            <>
                                                {plan.unit_amount === 0
                                                    ?
                                                    <button type="submit" className="button button-main" disabled={processingFreePlan || processing} onClick={() => { closeModal() }}>
                                                        {processingFreePlan
                                                            ? <div className="spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>

                                                            : "Continue with free plan"
                                                        }
                                                    </button>

                                                    :
                                                    <button type="submit" className="button button-main" disabled={processingFreePlan || processing} onClick={() => { createCheckoutSession(plan.id) }}>
                                                        {processing
                                                            ? <div className="spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>

                                                            : "Upgrade Plan"
                                                        }
                                                    </button>
                                                }
                                            </>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default Subscriptions