import React, { useContext, useEffect, useState } from 'react'
import RegistrationForm from '../components/RegistrationForm'
import { UserContext } from '../context/UserProvider'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Register = () => {
  const [user] = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    if (user.accessToken === "") {
      setLoading(false)
    } else {
      navigate('/')
    }
  }, [user])
  return (
    <>
      <Helmet>
        <title>Register - SageCollab</title>
        <meta name="description" content="Create a SageCollab account to join our community and brings team collaboration features to generative AI. Register now to get started!" />
        <meta name="keywords" content="register, SageCollab, sign up, join community, team collaboration, collaboration, generative AI, generative AI collaboration" />
        <meta property="og:title" content="Register - SageCollab" />
        <meta property="og:description" content="Create a SageCollab account to join our community and brings team collaboration features to generative AI. Register now to get started!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.sagecollab.com/register" />
        <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Register - SageCollab" />
        <meta name="twitter:description" content="Create a SageCollab account to join our community and brings team collaboration features to generative AI. Register now to get started!" />
        <meta name="twitter:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
      </Helmet>

      <div className='container box'>
        {!loading
          &&
          <RegistrationForm />
        }
      </div>
    </>
  )
}

export default Register