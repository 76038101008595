import React, { useContext, useEffect, useState } from 'react'
import NewPrompt from '../components/NewChat'
import { UserContext } from '../context/UserProvider'
import Template from '../components/SavedPrompts'
import Modal from 'react-bootstrap/Modal';
import Subscriptions from '../components/Subscriptions';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import TabsWithView from '../components/TabsWithView';
import { Helmet } from 'react-helmet';

const DashboardUser = ({ updateTokens, tokens, socket }) => {
  const [user, setUser] = useContext(UserContext)

  const [show, setShow] = useState(false)
  // const handleClose = async () => { await updatePlansModalShownStatus(); setShow(false) }
  const handleShow = () => setShow(true)

  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const handleShowPaymentMethodModal = () => setShowPaymentMethodModal(true)

  useEffect(() => {

    if (!user?.plans_model_shown) {
      if (user.role_id === 2) {
        // handleShow()  //this model is removed from the flow
      }
      else if (user.role_id === 4) {
        handleShowPaymentMethodModal()
      }
    }
  }, [user])

  const handleClose = async () => {
    ProtectedAxios.post('/users/updatePlansModelShownStatus', { user_id: user.user_id })
      .then(res => {
        if (res.data) {
          setUser({ ...user, plans_model_shown: 1 })
          setShow(false)
        }
      })
      .catch(err => {
        console.log(err);
      })
  }



  return (
    <div className='container pt-5'>
      <Helmet>
        <title>Dashboard - SageCollab</title>
      </Helmet>

      <TabsWithView />

      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Subscription Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Subscriptions closeModal={handleClose} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleClose}>
            close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DashboardUser