import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineHistory } from "react-icons/ai"
import { RiUserReceivedLine } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'
import { BiBookmark } from 'react-icons/bi'
import { HiOutlineTemplate } from 'react-icons/hi'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import { useLocation, useNavigate } from 'react-router-dom'


const Tabs = ({ showingInMobileNav = false, setIsMenuOpen, className, style }) => {
    const [subscriptionDetail] = useSubscriptionDetails()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    let activeTab = parseInt(queryParams.get('activeTab')) || null

    let tabsRef = useRef(null)
    const navigate = useNavigate()

    return (
        <div className={`tabs ${className}`} ref={tabsRef} style={style}>
            <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => { navigate("/?activeTab=0"); if (showingInMobileNav) { setIsMenuOpen(false) } }}><AiOutlineHistory />History</div>
            <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => { navigate("/?activeTab=1"); if (showingInMobileNav) { setIsMenuOpen(false) } }}><RiUserReceivedLine />Shared</div>
            {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                &&
                <div className={`tab ${activeTab === 2 ? "active" : ""}`} onClick={() => { navigate("/?activeTab=2"); if (showingInMobileNav) { setIsMenuOpen(false) } }}><FiUsers />Organization</div>
            }
            <div className={`tab ${activeTab === 3 ? "active" : ""}`} onClick={() => { navigate("/?activeTab=3"); if (showingInMobileNav) { setIsMenuOpen(false) } }}><BiBookmark />Saved</div>
            <div className={`tab ${activeTab === 4 ? "active" : ""}`} onClick={() => { navigate("/?activeTab=4"); if (showingInMobileNav) { setIsMenuOpen(false) } }}><HiOutlineTemplate />Templates</div>
        </div>
    )
}

export default Tabs