import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { MdGppGood } from 'react-icons/md'
import { useLocation, useNavigate, useHistory, useParams } from 'react-router-dom';
import ProtectedAxios from '../api/protectedAxios';
import { UserContext } from '../context/UserProvider';
import { Helmet } from 'react-helmet';

const TransactionSucceeded = ({ updateTokens }) => {
  const [user, setUser] = useContext(UserContext)
  const [time, setTime] = useState(5);
  const navigate = useNavigate()
  const location = useLocation()
  let { session_id } = useParams()
  const [loading, setLoading] = useState(true)
  let mounted = false

  setTimeout(() => {
    if (time === 0) {
      navigate('/')
    } else {
      if (!loading) {
        setTime(time - 1)
      }
    }
  }, 1000)

  useEffect(() => {
    updateUsersSubscription()
  }, [])

  const updateUsersSubscription = () => {
    if (session_id) {
      if (!mounted) {
        setLoading(true)
        mounted = true
        ProtectedAxios.post('/subscription/subscriptionUpdate', { session_id, user_id: user.user_id })
          .then(res => {
            if (res.data) {
              try {
                updateTokens()
              }
              catch (err) {
                console.log(err)
              }

              if (res.data.stripe_subscription_id) {
                setUser({ ...user, stripe_subscription_id: res.data.stripe_subscription_id })
              }
              toast("Congratulations! Your subscription has been added. Enjoy the features and benefits of our Standard plan.", {
                icon: "🎉"
              })
              setLoading(false)
            }
          })
          .catch(err => {
            setLoading(false)
            navigate('/page-not-found')
          })
      }
    }
    else {
      navigate('/page-not-found')
    }
  }
  return (
    <div className='container box'>
      <Helmet>
        <title>Success - SageCollab</title>
      </Helmet>

      {loading
        ? <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
          <div className="spinner-border spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>

        :
        <div className='flexed-container column'>
          <MdGppGood className='declined-icon text-success' />
          <h2>Transaction Completed</h2>
          <p className='text-center'>Your transaction have been successfully completed. Redirecting you back to Home page in {time}s...</p>
          {/* <button className="button button-main" onClick={() => { }}>Go Back</button> */}
        </div>
      }
    </div>
  )
}

export default TransactionSucceeded